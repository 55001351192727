<!-- =========================================================================================
    File Name: ChatContact.vue
    Description: Chat contact - single component for chat
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div
    class="chat__contact flex items-center px-2 py-3"
    :class="{ 'bg-primary-gradient text-white shadow-lg': isActiveChatUser }"
  >
    <div class="flex contact__avatar mr-3">
      <vs-avatar
        class="border-2 border-solid border-white"
        :src="contact.profilePic"
        size="45px"
      ></vs-avatar>
      <vs-avatar
        v-if="contact.channel !== '-'"
        class="border-2 border-solid border-white"
        style="margin-left: -20px; margin-top: 28px"
        :src="
          require(`@/assets/images/portrait/icons/${
            contact.channel ? contact.channel.toLowerCase() : 'chat'
          }.png`)
        "
        size="25px"
      ></vs-avatar>
    </div>
    <div
      class="contact__container w-full flex items-center justify-between overflow-hidden"
    >
      <div class="contact__info flex flex-col" style="max-width: 225px">
        <vs-tooltip
          :text="`${contact.name || $t('UnknownName')} - ${toDateSmall(
            lastMessaged
          )}`"
          position="right"
        >
          <h5
            class="font-semibold truncate"
            :class="{ 'text-white': isActiveChatUser }"
          >
            {{ contactName }}
          </h5>
        </vs-tooltip>
        <span v-if="contact.intentions && contact.intentions.length > 0">
          <vs-chip class="mt-3">
            <vs-tooltip
              :text="contact.intentions[0]"
              position="right"
              class="truncate"
              style="max-width: 105px"
            >
              {{ contact.intentions[0] | capitalize }}
            </vs-tooltip>
          </vs-chip>
          <vs-chip v-if="contact.intentions.length > 1" class="mt-3">
            <vs-tooltip
              :text="intentionsList(contact.intentions)"
              position="right"
            >
              + {{ contact.intentions.length - 1 }}
            </vs-tooltip>
          </vs-chip>
        </span>
        <span v-else class="truncate">
          <vs-chip class="mt-3">{{ $t('Outros') }}</vs-chip>
        </span>
      </div>

      <div
        class="chat__contact__meta flex self-start flex-col items-end flex-1 mt-1"
      >
        <small class="whitespace-no-wrap mr-1">{{ contactTime }}</small>
        <div class="flex items-end justify-end">
          <div v-if="contact.postponedTo">
            <postponed-conversation-tag
              class="mt-2"
              :date="contact.postponedTo"
            />
          </div>
          <vs-avatar
            :color="notificationColor"
            text="!"
            size="20px"
            v-if="contact.pendingResponse"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import differenceInHours from "date-fns/differenceInHours";
import differenceInMinutes from "date-fns/differenceInMinutes/index.js";
import PostponedConversationTag from "../../../components/PostponedConversationTag.vue";

export default {
  components: { PostponedConversationTag },
  data() {
    return {
      contactTime: "",
      notificationColor: "",
      countEvent: () => {},
    };
  },
  props: {
    contact: {
      type: Object,
      required: true,
    },
    lastMessaged: {
      type: String,
      default: "",
    },
    unseenMsg: {
      type: Number,
      default: 0,
    },
    isActiveChatUser: {
      type: Boolean,
    },
    inboxWarningFlags: {
      type: Object,
    },
  },
  watch: {
    lastMessaged(val) {
      this.contactTime = this.calcTime(val);
    },
  },
  computed: {
    greenFlagTotal() {
      const hoursToMinutes = parseInt(this.inboxWarningFlags.green.hour) * 60;
      const minutes = parseInt(this.inboxWarningFlags.green.minute);
      const total = hoursToMinutes + minutes;
      return total;
    },
    redFlagTotal() {
      const hoursToMinutes = parseInt(this.inboxWarningFlags.red.hour) * 60;
      const minutes = parseInt(this.inboxWarningFlags.red.minute);
      const total = hoursToMinutes + minutes;
      return total;
    },
    contactName() {
      if (!this.contact.name) return this.$t("UnknownName");
      return this.contact.name.length > 14
        ? `${this.contact.name.slice(0, 14)}...`
        : this.contact.name;
    },
  },
  methods: {
    toDateSmall(time) {
      let fullDate = new Date(time);
      let valid = new Date(time).getTime() > 0;
      if (!valid) fullDate = new Date(time);

      let hour = fullDate.getHours() + ":";
      if (fullDate.getMinutes() < 10) hour += "0" + fullDate.getMinutes();
      else hour += fullDate.getMinutes();
      return `${hour}`;
    },
    getNotificationColor(time) {
      let date_time_to = new Date(Date.parse(time));
      let valid = new Date(time).getTime() > 0;
      if (!valid) date_time_to = new Date(time);
      let date_time_from = new Date();

      // let hours = differenceInHours(date_time_from, date_time_to);
      let minutes = differenceInMinutes(date_time_from, date_time_to);

      // const totalTime = (hours * 60) + minutes;
      const totalTime = minutes;

      if (totalTime <= this.greenFlagTotal) {
        return "success";
      }

      if (totalTime >= this.greenFlagTotal && totalTime <= this.redFlagTotal) {
        return "orange";
      }

      return "danger";
    },
    toDate(time) {
      let date_obj = new Date(Date.parse(time));
      let valid = new Date(time).getTime() > 0;
      if (!valid) date_obj = new Date(time);

      let locale = "en-us";
      let monthName = date_obj.toLocaleString(locale, {
        month: "short",
      });
      return date_obj.getDate() + " " + monthName;
    },
    intentionsList(intentions) {
      const intents = JSON.parse(JSON.stringify(intentions));
      intents.splice(0, 1);
      return intents.join(", ");
    },
    calcTime(time) {
      const thisIns = this;
      this.notificationColor = this.getNotificationColor(time);
      let date_time_to = new Date(Date.parse(time));
      let valid = new Date(time).getTime() > 0;
      if (!valid) date_time_to = new Date(time);
      let date_time_from = new Date();
      let finalDate;
      if (differenceInHours(date_time_from, date_time_to) < 24) {
        let hours = differenceInHours(date_time_from, date_time_to);
        let minutes = differenceInMinutes(date_time_from, date_time_to);

        if (hours <= 0) {
          if (minutes <= 0)
            this.countEvent = () => {
              setInterval(function () {
                thisIns.contactTime = thisIns.calcTime(thisIns.lastMessaged);
              }, 60000);
            };
          finalDate = minutes <= 0 ? `${this.$t("Now")}` : `${minutes}min`;
        } else {
          finalDate = `${hours}h`;
        }
      } else finalDate = this.toDate(time);

      return finalDate;
    },
  },
  mounted() {
    const thisIns = this;
    this.contactTime = this.calcTime(this.lastMessaged);
    setInterval(function () {
      thisIns.contactTime = thisIns.calcTime(thisIns.lastMessaged);
    }, 60000);
  },
  beforeDestroy() {
    clearInterval(this.countEvent);
  },
};
</script>
<style scoped>
.is-atd {
  display: none;
}
.chat__contact:hover > .contact__avatar,
.chat__contact:hover > .contact__container {
  opacity: 0.2 !important;
}
.chat__contact:hover > .is-atd {
  display: inline;
  position: absolute;
  right: 10px;
}
</style>
