<!-- =========================================================================================
    File Name: Chat.vue
    Description: Chat Application - Stay connected
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <dynamic-title :title="notificationTitle + 'duotalk'"></dynamic-title>
    <div class="absolute z-9999999 flex">
      <vs-button
        v-if="isChatSidebarActive"
        type="flat"
        color="dark"
        icon-pack="feather"
        icon="icon-arrow-left"
        radius
        size="large"
        @click.stop="$router.push({ name: 'dashboard' })"
      />
      <vs-tooltip
        text="Ir para versão nova da inbox"
        position="right"
        class="mt-3"
        delay=".5s"
      >
        <a href="/apps/inbox"><small>Ir para v2</small></a>
      </vs-tooltip>
    </div>
    <div id="chat-app" ref="chatFrame" class="w-screen h-full overflow-hidden">
      <!-- Add conversation popup -->
      <vs-popup
        id="popup-inbox-conversation"
        :title="$t('AddConversationTitle')"
        :active.sync="addConversationPopup"
      >
        <div class="px-4 py-4" v-if="!addConversationLoading">
          <div class="flex mb-3">
            <v-select
              :options="conversationSearchContacts"
              :loading="addConversationSearchLoading"
              label="search"
              class="w-full"
              v-model="addConversationContact"
              @search="addConversationSearchContacts"
              :placeholder="$t('SearchContact')"
            >
              <!-- eslint-disable-next-line vue/no-unused-vars  -->
              <template #no-options>
                <span v-if="addConversationOnSearch">
                  {{ $t("NoContactFounded") }}
                </span>
                <span v-else>-</span>
              </template>
              <template v-slot:option="contact">
                <div class="chat__contact flex items-center">
                  <div class="contact__avatar mr-3">
                    <vs-avatar
                      class="border-2 border-solid border-white"
                      :src="contact.profilePic"
                      size="30px"
                    ></vs-avatar>
                  </div>
                  <div
                    class="contact__container w-full flex items-center justify-between overflow-hidden"
                  >
                    <div
                      class="contact__info flex flex-col"
                      style="max-width: 225px"
                    >
                      <span class="font-semibold truncate bold">
                        {{ contact.name }}
                      </span>
                      <span class="truncate">
                        {{ contact.phone }}
                      </span>
                    </div>
                  </div>
                </div>
              </template>
              <template v-slot:selected-option="contact">
                <div class="chat__contact flex items-center">
                  <vs-avatar
                    class="border-2 border-solid border-white"
                    :src="contact.profilePic"
                    size="30px"
                  ></vs-avatar>
                  <span
                    class="font-semibold truncate bold text-base px-1 block overflow-hidden whitespace-nowrap text-ellipsis"
                    style="max-width: 110px"
                  >
                    {{ contact.name }}
                  </span>
                  <span class="truncate text-xs pl-1">
                    {{ contact.phone }}
                  </span>
                </div>
              </template>
              <template v-slot:spinner="{ addConversationSearchLoading }">
                <div
                  v-if="addConversationSearchLoading"
                  style="border-left-color: rgba(88, 151, 251, 0.71)"
                  class="vs__spinner"
                ></div>
              </template>
            </v-select>
          </div>
          <div class="flex-wrap mb-3 w-full hidden">
            <v-select
              :clearable="false"
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
              class="w-full"
              :placeholder="$t('SearchContact')"
            />
          </div>
          <div
            class="flex flex-wrap mb-3 w-full"
            v-if="!addConversationNameDisabled"
          >
            <vs-input
              class="no-icon-border w-full"
              v-model="addConversationName"
            />
            <div class="label-onboarding z-10">
              <i class="vs-icon feather icon icon-user pr-1"></i>
              {{ $t("Name") }}
            </div>
          </div>
          <div
            class="flex flex-wrap mb-3 w-full"
            v-if="!addConversationPhoneDisabled"
          >
            <vue-tel-input
              v-model="addConversationPhone"
              @validate="validatePhone"
              :mode="'auto'"
              class="w-full py-1 px-3"
            />
            <span v-if="!validPhone" class="text-danger text-sm">
              {{ $t("InvalidPhone") }}
            </span>
            <div class="label-onboarding">
              <i class="vs-icon feather icon icon-phone mr-1"></i>
              {{ $t("Phone") }}
            </div>
          </div>
          <div
            class="flex flex-wrap mb-3 w-full"
            v-if="this.chatOptions.length > 1"
          >
            <v-select
              class="no-icon-border w-full"
              v-model="addConversationChat"
              :clearable="false"
              :options="this.chatOptions"
              name="entity"
            />
            <div class="label-onboarding z-10">
              <i class="vs-icon feather icon icon-menu mr-1"></i>
              {{ $t("Chatbot") }}
            </div>
          </div>
          <div class="flex flex-wrap mb-3 w-full" v-if="addConversationChat">
            <v-select
              class="no-icon-border w-full"
              v-model="addConversationIntention"
              :clearable="false"
              :options="operatorIntentions"
              name="segment"
            />
            <div class="label-onboarding bg-white z-10">
              <i class="vs-icon feather icon icon-grid mr-1"></i
              >{{ $t("Department") }}
            </div>
          </div>
          <div
            class="flex flex-wrap mb-3 w-full"
            v-if="operatorEntities.length > 0 && addConversationChat"
          >
            <v-select
              class="no-icon-border w-full"
              v-model="addConversationEntity"
              :clearable="false"
              :options="operatorEntities"
              name="entity"
              v-if="operatorEntities.length > 0"
            />
            <div class="label-onboarding bg-white z-10">
              <i class="vs-icon feather icon icon-menu mr-1"></i>
              {{ $t("EntityKey") }}
            </div>
          </div>
          <div
            v-if="
              !(
                this.$store.state.user.userRole === 'operator' &&
                blockCreateConversationOperator
              )
            "
          >
            <vs-button
              type="filled"
              @click="addConversation()"
              color="success"
              class="w-full mt-12"
            >
              {{ $t("StartConversation") }}
            </vs-button>
          </div>
        </div>
        <div class="w-full flex justify-center p-12" v-else>
          <span class="material-icons-outlined animate-spin text-9xl">
            sync
          </span>
        </div>
      </vs-popup>

      <!-- Quick messages popup -->
      <vs-popup :title="$t('QuickMessages')" :active.sync="quickMessagesPopup">
        <vs-row v-if="!addConversationLoading">
          <vs-col vs-w="12">
            <vs-input
              icon="icon-search"
              icon-pack="feather"
              class="no-icon-border w-full"
              :placeholder="$t('Search')"
              v-model="searchQueryQuickMessages"
              @keyup="filterQuickMessages"
              type="search"
            />
          </vs-col>

          <vs-col vs-w="12">
            <vs-alert
              v-for="(message, index) in inboxQuickMessagesFiltered"
              :key="index"
              color="dark"
              :title="message.title"
              class="mt-3 cursor-pointer"
              @click="selectQuickMessage(message.message)"
            >
              {{ message.message.substring(0, 60) }}
            </vs-alert>
          </vs-col>
        </vs-row>
        <div v-else class="p-12">
          <span class="material-icons-outlined animate-spin text-9xl">
            sync
          </span>
        </div>
      </vs-popup>

      <!-- Create quick messages popup -->
      <vs-popup
        :title="$t('QuickMessages')"
        :active.sync="createQuickMessagesPopup"
      >
        <vs-row>
          <vs-col vs-w="12">
            <vs-input
              name="email"
              v-model="createQuickMessages.title"
              type="email"
              class="w-full"
              :label="$t('Title')"
            />
          </vs-col>
          <vs-col vs-w="12" class="mt-4">
            <div
              class="vs-component vs-con-input-label vs-input w-full vs-input-primary"
            >
              <label class="vs-input--label">{{ $t("Intention") }}</label>
              <v-select
                v-model="createQuickMessages.intentions"
                :options="createQuickMessagesOptions.intentions"
                multiple
              />
            </div>
          </vs-col>
          <vs-col
            vs-w="12"
            class="mt-4"
            v-if="createQuickMessagesOptions.entities.length > 0"
          >
            <div
              class="vs-component vs-con-input-label vs-input w-full vs-input-primary"
            >
              <label class="vs-input--label">{{ $t("KeyEntities") }}</label>
              <v-select
                v-model="createQuickMessages.entities"
                :options="createQuickMessagesOptions.entities"
                multiple
              />
            </div>
          </vs-col>
          <div class="mt-4">
            <label class="vs-select--label">{{ $t("Media") }}</label>
            <div>
              <input
                type="file"
                @change="quickMessagePreviewFile"
                ref="inputMedia"
                accept="*"
              />
            </div>
          </div>

          <div class="mt-4" v-if="quickMessageMedia">
            <video
              v-if="quickMessageMediaMimetype.includes('video')"
              controls="controls"
              width="50%"
            >
              <source :src="quickMessageMediaUrl" />
              <!--Suportado em IE9, Chrome 6 e Safari 5 -->
              O seu navegador não suporta a tag vídeo
            </video>
            <img
              v-else-if="quickMessageMediaMimetype.includes('image')"
              :src="quickMessageMediaUrl"
              width="50%"
            />
          </div>
          <vs-col vs-w="12" class="mt-4">
            <vs-textarea
              name="email"
              v-model="createQuickMessages.message"
              class="w-full"
              :placeholder="$t('WriteMessage') + '...'"
              rows="5"
            />
            <small>
              * {{ $t("Enter") }} {NOME_ATENDENTE}
              {{ $t("AddAttendantsName") }} {NOME_CONTATO}
              {{ $t("ContactName") }}
            </small>
          </vs-col>
          <vs-col vs-w="12" vs-type="flex" vs-justify="flex-end" class="mt-4">
            <vs-button
              icon-pack="feather"
              icon="icon-save"
              size="medium"
              @click="createQuickMessage()"
            >
              {{ $t("Save") }}
            </vs-button>
          </vs-col>
        </vs-row>
      </vs-popup>

      <!-- Custom templates -->
      <vs-popup
        :title="$t('EditVariablesTemplates')"
        :active.sync="editVariablesTemplatesPopup"
      >
        <vs-row>
          <vs-col vs-w="12">
            <div class="con-vs-alert con-vs-alert-primary con-icon">
              <div class="vs-alert con-icon">
                <i
                  class="vs-icon notranslate icon-scale icon-alert feather icon-info null"
                ></i>
                {{ $t("TemplateHasCustomizableVariables") }}
                <a
                  href="https://ajuda.duotalk.io/pt/articles/52-enviar-mensagens-com-variaveis"
                  target="_blank"
                  class="underline"
                  >{{ $t("HowItWorks") }}</a
                >
              </div>
            </div>
          </vs-col>

          <vs-col>
            <vs-divider />
          </vs-col>

          <vs-col>
            <h5 class="text-dark">{{ $t("HowWouldLikeToReplace") }}</h5>
          </vs-col>

          <vs-col
            class="mt-4"
            v-for="(item, index) in editVariablesTemplate"
            :key="index"
          >
            <vs-input
              :label="index.toLowerCase() | capitalize"
              class="w-full"
              maxlength="60"
              :name="index.toLowerCase() | capitalize"
              v-model="editVariablesTemplate[index]"
            />
          </vs-col>

          <vs-col>
            <vs-divider />
          </vs-col>

          <vs-col vs-type="flex" vs-justify="space-between" vs-align="center">
            <h5>{{ $t("PreviewTemplate") }}</h5>
            <vs-button
              type="border"
              color="primary"
              icon-pack="feather"
              :icon="showTemplate ? 'icon-eye-off' : 'icon-eye'"
              icon-after
              class="px-2"
              @click="showTemplate = !showTemplate"
            >
              {{ showTemplate ? $t("Hide") : $t("View") }}
            </vs-button>
          </vs-col>

          <vs-col
            vs-type="flex"
            vs-align="center"
            class="mt-4"
            v-if="showTemplate"
          >
            <vs-textarea
              v-model="templateData.message"
              rows="11"
              disabled
              readonly
            />
          </vs-col>

          <vs-col vs-w="12" vs-type="flex" vs-justify="flex-end" class="mt-4">
            <vs-button
              icon-pack="feather"
              icon="icon-save"
              size="medium"
              @click="send360TemplateMessage"
              icon-after
            >
              {{ $t("Send") }}
            </vs-button>
          </vs-col>
        </vs-row>
      </vs-popup>

      <div>
        <vs-sidebar
          class="items-no-padding"
          parent="#chat-app"
          :click-not-close="clickNotClose"
          :hidden-background="clickNotClose"
          v-model="isChatSidebarActive"
          id="chat-list-sidebar"
        >
          <div class="w-full px-5 mt-8">
            <vs-duotalk-inbox-tabs
              alignment="fixed"
              v-model="activeTab"
              id="tabs-inbox"
              ref="tabArchive"
              :counterBadge="[waitingAttendanceLength, inAttendanceLength, 0]"
              :blockWaitingServiceTab="blockWaitingServiceTab"
            />
          </div>

          <div
            id="addConversation"
            class="flex justify-between w-full p-5-mutaded drop-shadow"
          >
            <vs-input
              icon="icon-search"
              icon-pack="feather"
              size="normal"
              class="no-icon-border w-64"
              :placeholder="$t('SearchContact')"
              v-model="searchQueryLocal"
              @keyup="filterContacts"
            />
            <vs-dropdown
              v-if="activeTab !== 2"
              vs-custom-content
              class="self-center cursor-pointer"
              vs-trigger-click
            >
              <vs-button
                icon="icon-chevron-down"
                icon-pack="feather"
                icon-after
                :type="filterIsActive"
                class="flex justify-center cursor-pointer w-24"
              >
                {{ $t("Filters") }}
              </vs-button>
              <vs-dropdown-menu
                class="w-1/6"
                style="min-width: 320px; max-height: calc(100vh - 145px)"
              >
                <div class="p-4">
                  <h6 class="mb-2">{{ $t("Departments") }}:</h6>
                  <div
                    class="flex flex-wrap mt-6 overflow-x-auto"
                    style="max-height: calc(30vh)"
                  >
                    <div class="w-full flex mb-3">
                      <vs-switch v-model="selectAllIntentions" />
                      <label class="text-base ml-2 whitespace-nowrap">
                        {{ $t("SelectAll") }}
                      </label>
                    </div>
                    <div
                      class="w-full flex mb-3"
                      v-for="(intention, index) in intentionsArrayStored"
                      v-bind:key="index"
                    >
                      <vs-switch
                        v-model="intentionsArray"
                        :vs-value="intention"
                      />
                      <label class="text-md opacity-85 ml-2 whitespace-nowrap">
                        {{ formatIntentionName(intention) }} ({{
                          countIntentionConversations(intention)
                        }})
                      </label>
                    </div>
                  </div>
                </div>
                <vs-divider
                  v-if="currentOperatorIsManager"
                  class="d-theme-border-grey-light my-0"
                />
                <div v-if="currentOperatorIsManager" class="p-4">
                  <h6 class="mb-2">{{ $t("Operators") }}:</h6>
                  <div
                    class="flex flex-wrap mt-6 overflow-x-auto"
                    style="max-height: calc(30vh)"
                  >
                    <div class="w-full flex mb-3">
                      <vs-switch v-model="selectAllOperators" />
                      <label class="text-md opacity-85 ml-2 whitespace-nowrap">
                        {{ $t("SelectAll") }}
                      </label>
                    </div>
                    <div
                      class="w-full flex mb-3"
                      v-for="(operator, index) in inboxOperatorsArrayStored"
                      v-bind:key="index"
                    >
                      <vs-switch
                        v-model="inboxOperatorsArray"
                        :vs-value="operator.value"
                      />
                      <label class="text-md ml-2 whitespace-nowrap">
                        {{ operator.label }} ({{
                          countOperatorConversations(operator.value)
                        }})
                      </label>
                    </div>
                  </div>
                </div>
              </vs-dropdown-menu>
            </vs-dropdown>
          </div>

          <vs-divider v-if="!socketConnOnline || attendanceSync" />

          <vs-alert
            :active="!socketConnOnline"
            :title="$t('YoureOffline')"
            color="warning"
            icon-pack="feather"
            icon="icon-wifi-off"
          >
            {{ $t("NetworkIssues") }}
          </vs-alert>

          <vs-alert
            title="Sync"
            color="success"
            icon-pack="feather"
            icon="icon-refresh-ccw"
            :active="attendanceSync"
          >
            {{ $t("SomeConversationsMayNotAppear") }}.

            <vs-progress class="block mt-2" indeterminate color="success" />
          </vs-alert>

          <vs-divider />

          <div class="flex mb-4 px-4 items-center justify-between">
            <div v-if="activeTab !== 2" class="flex items-center">
              <small class="text-primary">
                {{ $t("TotalConversationsNumber") }} ({{
                  currentTabChats.length
                }})
              </small>
              <vs-tooltip text="Filtrar Pendentes">
                <vs-button
                  icon-pack="feather"
                  icon="icon-alert-circle"
                  radius
                  @click="filterUnreadOnly = !filterUnreadOnly"
                  class="ml-3"
                  :class="[
                    filterUnreadOnly ? 'vs-button-filled' : 'vs-button-flat',
                  ]"
                />
              </vs-tooltip>
            </div>
            <small v-else class="text-primary mt-2 mr-5">
              {{ $t("LastConversations") }} ({{ currentTabChats.length }})
            </small>
            <div
              v-if="
                !(
                  this.$store.state.user.userRole == 'operator' &&
                  blockCreateConversationOperator
                )
              "
            >
              <vs-button
                icon-pack="feather"
                icon="icon-message-square"
                type="border"
                size="default"
                class="justify-center w-50"
                @click="addConversationPopup = true"
              >
                {{ $t("NewConversation") }}
              </vs-button>
            </div>
          </div>

          <vs-divider class="mb-0" />

          <div class="absolute w-full">
            <!-- ACTIVE CHATS LIST -->
            <div
              id="contactListscrollArea"
              class="clusterize-scroll pt-3"
              ref="contactsList"
            >
              <ul
                id="contactListContentArea"
                class="clusterize-content relative"
                :style="`height: ${currentTabChats.length * 86}px`"
              >
                <li
                  v-for="(n, i) in conversationsLoadingSkeletonUp"
                  :key="`skeleton-up-${i}`"
                >
                  <chat-contact-skeleton />
                </li>
                <li
                  class="cursor-pointer contact-list-item absolute w-full"
                  v-for="(contact, i) in currentTabChatsPaginated"
                  :key="`${contact.contactSessionID}-${i}`"
                  :datapos="i + 1 + contactListItemStart"
                  :style="`transform: translateY(${
                    (i + contactListItemStart) * 86
                  }px); height:86px;`"
                  @click="updateActiveChatUser(contact, contact.id)"
                >
                  <chat-contact
                    :attendance="contact.inAttendance"
                    :contact="contact"
                    :lastMessaged="contact.updated"
                    :isActiveChatUser="isActiveChatUser(contact.id)"
                    :inboxWarningFlags="inboxWarningFlags"
                  />
                </li>
              </ul>
              <div
                class="flex mt-4 mb-4 px-4 items-center w-full justify-center"
              >
                <vs-button
                  v-if="!loadingConversationsSpin && showLoadMoreConversations"
                  class="my-4 rounded-4xl"
                  type="border"
                  size="small"
                  @click="loadMoreConversations()"
                >
                  {{ $t("LoadMoreConversations") }}
                </vs-button>
                <span
                  class="material-icons-outlined animate-spin text-9xl"
                  v-if="
                    loadingConversationsSpin &&
                    conversationsLoadingSkeletonUp.length === 0
                  "
                >
                  sync
                </span>
              </div>
            </div>
          </div>
        </vs-sidebar>
        <!-- MID COLUMN -->
        <div
          ref="chatlog"
          id="chatlog"
          class="chat__bg app-fixed-height chat-content-area vs-con-loading__container"
          :class="{
            'sidebar-spacer--wide-mid': clickNotClose,
            'flex items-center justify-center': activeChatUser === null,
          }"
        >
          <div
            v-if="showDropzone && !teamChatOpen"
            class="w-full h-full mr-5 backdrop-filter flex items-center justify-center flex-col"
          >
            <h1 class="font-bold">{{ $t("DropFilesHere") }}</h1>
          </div>
          <template v-if="activeChatUser">
            <div class="chat__navbar">
              <chat-navbar
                :contact="activeContact"
                :isSidebarCollapsed="!clickNotClose"
                :user-id="activeChatUser"
                :isPinnedProp="isChatPinned"
                :operators="inboxOperatorsArrayStored"
                :intentions="inboxIntentions"
                :entities="allEntities"
                :closingConversationReasons="closingConversationReasons"
                @set-operator="setOperator"
                @postpone-conversation="postponeConversation"
                @toggle-active-chat-user="activeChatUser = null"
                @open-contacts-sidebar="toggleChatSidebar(true)"
                @show-profile-sidebar="toogleProfileSidebar(true)"
                @close-profile-sidebar="toogleProfileSidebar(false)"
                @toggle-is-chat-pinned="toggleIsChatPinned"
                @close-conversation="closeConversation"
              ></chat-navbar>
            </div>
            <div
              class="w-full h-full p-7"
              style="background: #f4f4f4"
              v-if="filesToUpload.length > 0"
            >
              <div class="right-0">
                <feather-icon
                  class="mr-0 cursor-pointer"
                  icon="XCircleIcon"
                  svg-classes="stroke-current text-primary"
                  @click="cleanMessageInput"
                />
              </div>
              <div
                class="w-full content-center"
                style="display: table; height: calc(100vh - 240px)"
              >
                <div
                  class="align-middle text-center"
                  style="display: table-cell"
                  v-if="
                    filesToUploadPreview[
                      fileToUploadPreviewIndex
                    ].type.includes('image')
                  "
                >
                  <img
                    :src="
                      filesToUploadPreview[fileToUploadPreviewIndex].content
                    "
                    style="
                      max-width: 90%;
                      max-height: calc(100vh - 240px);
                      object-fit: contain;
                    "
                    class="h-full"
                  />
                </div>
                <div
                  v-else
                  class="align-middle text-center"
                  style="display: table-cell"
                >
                  <span
                    class="material-icons-outlined"
                    style="font-size: 16rem"
                  >
                    insert_drive_file
                  </span>
                  <div class="mt-3">
                    {{ filesToUploadPreview[fileToUploadPreviewIndex].name }}
                  </div>
                </div>
              </div>
              <vs-divider />
              <div
                class="w-full flex items-center justify-between bottom-0 inset-x-0"
              >
                <vue-perfect-scrollbar
                  class="w-full flex flex-nowrap overflow-y-auto chat-scroll-area"
                  :settings="{ swipeEasing: false }"
                >
                  <div
                    class="p-3 pl-0"
                    :key="`img-to-upload${index}`"
                    v-for="(file, index) in filesToUploadPreview"
                  >
                    <div
                      class="w-16 h-16 max-w-xs overflow-hidden rounded-lg shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out"
                    >
                      <vs-button
                        color="dark"
                        size="small"
                        icon-pack="feather"
                        icon="icon-x"
                        radius
                        class="absolute z-50 ml-10 -mt-2"
                        @click="removeImageToUpload(index)"
                      />
                      <img
                        v-if="file.type.includes('image')"
                        :src="file.content"
                        class="cursor-pointer w-16 h-16 relative inline-block object-cover"
                        @click="fileToUploadPreviewIndex = index"
                      />
                      <div
                        v-else
                        class="flex items-center justify-center h-full w-full cursor-pointer"
                      >
                        <feather-icon
                          icon="FileIcon"
                          @click="fileToUploadPreviewIndex = index"
                        />
                      </div>
                    </div>
                  </div>
                </vue-perfect-scrollbar>
                <vs-button
                  type="flat"
                  color="dark"
                  radius
                  icon-pack="feather"
                  icon="icon-folder-plus"
                  size="large"
                  @click="$refs.moreFile.click()"
                  class="mr-3"
                />
                <input
                  ref="moreFile"
                  multiple
                  name="fileUpload"
                  type="file"
                  class="hidden"
                  v-on:input="handleFilesBeforeUpload"
                />
                <div class="relative">
                  <vs-button
                    radius
                    color="success"
                    icon-pack="feather"
                    icon="icon-send"
                    size="large"
                    @click="sendMsg"
                  />
                  <div
                    class="absolute bg-white rounded-full w-8 h-8 flex items-center justify-center top-0 right-0 -mt-5 -mr-2 drop-shadow"
                  >
                    {{ filesToUploadPreview.length }}
                  </div>
                </div>
              </div>
            </div>

            <div class="relative">
              <div
                :class="{
                  chat__log: activeTab !== 2,
                  chat__log_closed: activeTab === 2,
                }"
                class="overflow-y-auto"
                ref="chatLog"
                @scroll="onChatLogScroll"
              >
                <chat-log
                  v-if="activeChatUser"
                  ref="chatLogComponent"
                  :key="`chatLogKey_${chatLogKey}`"
                  :cloudChats="cloudChats"
                  :userId="activeChatUser"
                  :contact="activeContact"
                  :participants="participants"
                  :contactName="activeContact.name || $t('UnknownName')"
                  :contactClosed="activeContactIsClosed"
                  :messagesListPage="messagesListPage"
                  :scrollMessagesListToBottom="scrollMessagesListToBottom"
                  @download-message-file="downloadMessageFile"
                  @resend-msg="resendMsg"
                  @onReplyMessage="onReplyMessage"
                  @onReaction="onReaction"
                  @chat-log-scroll-top="chatLogScrollTop"
                />
              </div>
              <div
                class="absolute bottom-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                v-if="showScrollMessagesListToBottom"
              >
                <vs-button
                  size="small"
                  color="primary"
                  type="filled"
                  class="flex"
                  icon="expand_more"
                  @click="
                    scrollMessagesListToBottom += 1;
                    showScrollMessagesListToBottom = false;
                  "
                >
                  {{ $t("BackToStart") }}
                </vs-button>
              </div>
            </div>

            <div
              v-if="
                messageFileUrl &&
                (messageFileMimetype.includes('video') ||
                  messageFileMimetype.includes('image'))
              "
              class="messageMediaPopup z-40"
            >
              <div class="flex">
                <video
                  v-if="messageFileMimetype.includes('video')"
                  controls="controls"
                  height="300"
                >
                  <source :src="messageFileUrl" />
                  O seu navegador não suporta a tag vídeo
                </video>
                <img
                  v-else-if="messageFileMimetype.includes('image')"
                  :src="messageFileUrl"
                  height="200"
                />
                <vs-button
                  class="ml-1"
                  color="danger"
                  size="small"
                  icon="icon-x"
                  icon-pack="feather"
                  @click="removeMessageFileUrl"
                ></vs-button>
              </div>
            </div>

            <!-- NEW QUICK MESSAGES -->
            <div v-if="newQuickMessageVisible" class="relative w-full">
              <div
                class="mb-3 h-64 rounded bottom-0 shadow-lg absolute z-9999999 overflow-y-auto block w-full p-6 bg-white border border-gray-200 shadow hover:bg-gray-100"
              >
                <div class="flex flex-col w-full">
                  <div class="flex w-full">
                    <h4 ref="newquickmessage-title" class="mb-3">
                      {{ $t("QuickMessages") }}
                    </h4>
                    <div class="right-0 absolute mr-4">
                      <button
                        type="button"
                        class="css-1bfpdd2"
                        @click="openCreateQuickMessagePopup()"
                        style="background-color: #28c76f"
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <div
                    :key="`${index}_quickMessageKey`"
                    v-for="(contact, index) in inboxNewQuickMessagesFiltered"
                  >
                    <div
                      tabindex="99999"
                      :ref="contact._id"
                      @click="selectNewQuickMessage(contact)"
                      @keyup.enter="selectNewQuickMessage(contact)"
                      class="contact__container w-full flex items-center justify-between overflow-hidden cursor-pointer"
                    >
                      <div
                        class="w-full overflow-hidden cursor-pointer truncate"
                      >
                        <div class="font-semibold truncate bold">
                          {{ contact.title }}
                        </div>
                        <div class="truncate">
                          {{ contact.message }}
                        </div>
                      </div>
                    </div>
                    <vs-divider class="m-0 mt-1 mb-1" />
                  </div>
                  <h4 ref="newquickmessage-title" class="my-3">
                    {{ $t("Templates") }}
                  </h4>
                  <div
                    :key="`${index}_templateKey`"
                    v-for="(
                      template, index
                    ) in inboxQuickMessagesWppAPIApprovedOnChatFiltered"
                  >
                    <div
                      tabindex="99999"
                      :ref="template._id"
                      @click="selectNewQuickMessage(template)"
                      @keyup.enter="selectNewQuickMessage(template)"
                      class="w-full overflow-hidden cursor-pointer truncate"
                    >
                      <div class="template__info flex flex-col text-black">
                        <div class="font-semibold bold">
                          {{ template.title }}
                        </div>
                        <div>
                          {{ template.message }}
                        </div>
                      </div>
                    </div>
                    <vs-divider class="m-0 mt-1 mb-1" />
                  </div>
                </div>
              </div>
            </div>

            <div
              v-if="!activeContact.closed"
              v-show="filesToUpload.length === 0"
              class="chat__input p-0 bg-white"
              id="chat-input"
            >
              <div
                v-if="!activeContact.inAttendance"
                style="
                  width: 100%;
                  height: 217px;
                  background-color: rgba(100, 100, 100, 0.2);
                  color: #fff;
                  display: flex;
                  align-items: center;
                  justify-content: space-around;
                  z-index: 10;
                "
              >
                <vs-button
                  class="inline-flex mr-3"
                  color="primary"
                  type="filled"
                  size="medium"
                  @click="startContactAttendance()"
                >
                  {{ $t("StartService") }}</vs-button
                >
              </div>
              <div class="p-3" style="height: 217px">
                <div class="flex">
                  <vs-select v-model="channel" width="180px">
                    <vs-select-item
                      v-for="(item, index) in inboxChannels"
                      :key="index"
                      :value="item"
                      :text="item"
                    />
                  </vs-select>

                  <vs-input
                    v-if="
                      activeContact.wppApiOn24hWindow === false &&
                      (channel === 'WhatsApp API' || channel === 'WhatsApp 360')
                    "
                    icon="icon-search"
                    icon-pack="feather"
                    size="normal"
                    class="no-icon-border w-64 ml-5"
                    placeholder="Pesquisar Templates"
                    v-model="searchTemplate"
                  />
                </div>
                <vs-divider class="mt-2 mb-1" />
                <vs-list
                  v-if="
                    activeContact.wppApiOn24hWindow === false &&
                    (channel === 'WhatsApp API' || channel === 'WhatsApp 360')
                  "
                  class="overflow-y-auto"
                  style="height: calc(100% - 40px)"
                >
                  <vs-list-item
                    v-for="(
                      item, i
                    ) in inboxQuickMessagesWppAPIApprovedOnChatFiltered"
                    :title="item.title"
                    :subtitle="item.message"
                    :key="i"
                  >
                    <vs-button
                      color="primary"
                      size="small"
                      class="ml-3"
                      :disabled="!socketConnOnline"
                      @click.stop="
                        () => {
                          return createCustomVariableObj(item);
                        }
                      "
                      >{{ $t("Send") }}</vs-button
                    >
                  </vs-list-item>
                </vs-list>

                <form class="css-j7qwjs" v-else>
                  <div
                    class="flex flex-nowrap p-1 lg:h-12 xl:h-auto 2xl:h-auto"
                  >
                    <div v-if="audioSource" class="flex items-center my-5">
                      <audio :src="audioSource" controls />
                      <vs-button
                        icon-pack="feather"
                        icon="icon-trash"
                        radius
                        @click="cleanMessageInput()"
                        class="ml-2"
                      />
                    </div>
                    <textarea
                      v-else
                      tabindex="1"
                      v-model="typedMessage"
                      @keyup="inspectBar"
                      placeholder="Escreva uma resposta..."
                      id="input-textarea-message"
                      ref="inputTextareaMessage"
                      class="border-0 text-base text-black outline-0 leading-5 w-full resize-none chat-textArea"
                      @paste="
                        pasteOnTextBox($event, channelAcceptMedia(channel))
                      "
                      rows="6"
                    >
                    </textarea>
                  </div>
                  <div class="flex justify-between p-2">
                    <div class="flex items-center">
                      <template
                        v-if="channelAcceptMedia(channel) && !audioSource"
                      >
                        <vs-button
                          radius
                          color="rgba(128, 128, 128, .15)"
                          text-color="black"
                          icon-pack="feather"
                          icon="icon-paperclip"
                          class="hover:bg-primary hover:text-white"
                          v-if="
                            channelMediaData(
                              channel
                            ).mediaAcceptedTypes.includes('file') ||
                            channelMediaData(
                              channel
                            ).mediaAcceptedTypes.includes('video') ||
                            channelMediaData(
                              channel
                            ).mediaAcceptedTypes.includes('image')
                          "
                          @click="channelAddFileOnClick()"
                        />
                        <input
                          multiple
                          id="file-upload"
                          type="file"
                          class="hidden"
                          v-on:input="handleFilesBeforeUpload"
                          :accept="
                            channelMediaData(
                              channel
                            ).mediaAcceptedExtensions.join(',')
                          "
                          ref="addFile"
                        />
                        <vs-button
                          v-if="
                            channelMediaData(
                              channel
                            ).mediaAcceptedTypes.includes('audio')
                          "
                          :radius="!recorder ? true : false"
                          color="rgba(128, 128, 128, .15)"
                          text-color="black"
                          icon-pack="feather"
                          :icon="
                            !recorder || recorder.state !== 'recording'
                              ? 'icon-mic'
                              : ''
                          "
                          @click="handleRecording()"
                          class="ml-2 hover:bg-primary hover:text-white"
                          :class="
                            !recorder || recorder.state !== 'recording'
                              ? ''
                              : 'animate-pulse'
                          "
                        >
                          <span
                            class="flex text-9xl items-center"
                            v-if="recorder"
                          >
                            <vs-icon class="mr-2" icon="stop" color="red" />
                            {{
                              `${mediaAudioRecordingMin}: ${mediaAudioRecordingSec}`
                            }}
                          </span>
                        </vs-button>
                      </template>

                      <twemoji-picker
                        :emojiData="emojiDataAll"
                        :emojiGroups="emojiGroups"
                        :skinsSelection="true"
                        :searchEmojisFeat="true"
                        :pickerWidth="emojiPickerSize"
                        @emojiUnicodeAdded="insertEmoji"
                        :searchEmojiPlaceholder="$t('Search')"
                        searchEmojiNotFound="Nenhum Emoji encontrado"
                        isLoadingLabel="Carregando..."
                        :key="emojiKeyForceRerender"
                        twemojiPath="https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/"
                        class="ml-2"
                      >
                        <template v-slot:twemoji-picker-button>
                          <vs-button
                            radius
                            color="rgba(128, 128, 128, .15)"
                            class="hover:bg-primary"
                            @click="channelAddEmoteOnClick()"
                          >
                            <img
                              src="https://app.duotalk.io/img/smiling.e1cb388a.png"
                              width="18px"
                              alt="smile icon"
                            />
                          </vs-button>
                        </template>
                      </twemoji-picker>

                      <vs-dropdown
                        vs-custom-content
                        class="ml-2 cursor-pointer group"
                        vs-trigger-click
                        :key="dropDownKey"
                      >
                        <vs-button
                          radius
                          color="rgba(128, 128, 128, .15)"
                          text-color="black"
                          icon-pack="feather"
                          icon="icon-file-text"
                          class="group-hover:text-white group-hover:bg-primary"
                        />
                        <vs-dropdown-menu>
                          <div class="h-80 w-96 overflow-y-auto p-3">
                            <div
                              class="flex w-full items-center justify-between"
                            >
                              <h5>{{ $t("QuickMessages") }}</h5>

                              <vs-button
                                color="success"
                                icon-pack="feather"
                                icon="icon-plus"
                                radius
                                size="small"
                                @click="createQuickMessagesPopup = true"
                              />
                            </div>
                            <vs-input
                              v-model="searchQueryQuickMessages"
                              :placeholder="$t('Search')"
                              class="w-full mt-3"
                            />
                            <vs-alert
                              v-for="(
                                item, index
                              ) in filterTemplateQuickMessages"
                              :key="index"
                              :title="item.title"
                              color="dark"
                              class="mt-3 cursor-pointer"
                              @click="selectedQuickMessage = item"
                            >
                              <div class="truncate">{{ item.message }}</div>
                            </vs-alert>
                          </div>
                        </vs-dropdown-menu>
                      </vs-dropdown>

                      <!-- CHATGPT SUGGESTION -->
                      <vs-button
                        v-if="accHasAIFeature"
                        @click.prevent="generateChatGptSuggestion()"
                        class="ml-4 px-2 suggestionBtn"
                        icon-pack="feather"
                        :icon="
                          !this.chatGptSuggestionGenerated
                            ? 'icon-edit-2'
                            : 'icon-refresh-ccw'
                        "
                        color="warning"
                        type="border"
                        text-color="black"
                      >
                        {{
                          this.chatGptSuggestionGenerated
                            ? $t("Redo")
                            : $t("SuggestAnswer")
                        }}
                      </vs-button>

                      <!-- Resumo de Conversa e Análise de sentimento-->
                      <vs-dropdown
                        v-if="accHasAIFeature"
                        vs-custom-content
                        vs-trigger-click
                        class="ml-4 cursor-pointer group dropdownActionsBtn"
                      >
                        <vs-button
                          type="border"
                          color="warning"
                          class="px-2 group-hover:dropdownActionsBtn"
                        >
                          <ThunderIcon />
                        </vs-button>
                        <vs-dropdown-menu class="dropdownActionsListBtn">
                          <vs-dropdown-item>
                            <vs-button
                              color="warning"
                              type="flat"
                              icon-pack="feather"
                              icon="icon-file-text"
                              text-color="black"
                              class="px-2"
                              @click="resumeConversation"
                            >
                              Resumir Conversa
                            </vs-button>
                          </vs-dropdown-item>
                          <vs-dropdown-item>
                            <vs-button
                              color="warning"
                              type="flat"
                              icon-pack="feather"
                              icon="icon-heart"
                              text-color="black"
                              class="px-2"
                              @click="sentimentAnalysis"
                            >
                              Análise de Sentimento
                            </vs-button>
                          </vs-dropdown-item>
                        </vs-dropdown-menu>
                      </vs-dropdown>
                    </div>
                    <vs-button
                      @click="sendMsg"
                      color="success"
                      icon-pack="feather"
                      icon="icon-send"
                      :disabled="!socketConnOnline"
                    >
                      {{ $t("Send") }}
                    </vs-button>
                  </div>
                </form>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="flex flex-col items-center">
              <feather-icon
                icon="MessageSquareIcon"
                class="mb-4 bg-white p-8 shadow-md rounded-full"
                svgClasses="w-16 h-16"
                @click.stop="toggleChatSidebar(true)"
              ></feather-icon>
              <h4
                class="py-2 px-4 bg-white shadow-md rounded-full cursor-pointer"
                @click.stop="toggleChatSidebar(true)"
                v-if="!this.clickNotClose"
              >
                {{ $t("SeeContactsAndStartConversation") }}
              </h4>
            </div>
          </template>
        </div>

        <vs-sidebar
          parent="#chat-app"
          :hidden-background="true"
          v-model="activeProfileSidebar"
          :position-right="true"
          :click-not-close="true"
          id="chat-profile-info-sidebar"
        >
          <contact-profile
            v-if="activeChatUser !== null"
            :conversationID="activeContact.contactSessionID || null"
            :contactName="activeContact.name || $t('UnknownName')"
            :contactEmail="activeContact.email"
            :contactPhone="activeContact.phone"
            :contactPhase="activeContact.phase"
            :contactProfilePic="activeContact.profilePic"
            :closed="activeContact.closed"
            :activeContact="activeChatUser"
            :phaseList="phaseList"
            :contactID="activeContact.contactID"
            @close-profile-sidebar="toogleProfileSidebar(false)"
            @init-add-conversation-popup="initAddConversationPopup"
            @update-contact="updateContactProp"
            class="user-profile-container"
            :showLoading="false"
            @close-conversation="closeConversation"
            :status="activeContact.contactStatus"
          />
        </vs-sidebar>
      </div>
    </div>
  </div>
</template>

<script>
import "@/assets/scss/vuesax/apps/chat.scss";
import newConversationNotification from "@/assets/sounds/chat/newConversationNotification.mp3";
import newMessageNotification from "@/assets/sounds/chat/newMessageNotification.mp3";
import sendMessageNotification from "@/assets/sounds/chat/sendMessageNotification.mp3";
import DynamicTitle from "@/components/DynamicTitle.vue";
import ContactProfile from "@/components/duotalk-contact-profile/ContactProfile.vue";
import VsDuotalkInboxTabs from "@/components/duotalk-inbox-tabs/VsDuotalkInboxTabs.vue";
import { TwemojiPicker } from "@kevinfaguiar/vue-twemoji-picker";
import EmojiGroups from "@kevinfaguiar/vue-twemoji-picker/emoji-data/emoji-groups.json";
import EmojiAllData from "@kevinfaguiar/vue-twemoji-picker/emoji-data/pt/emoji-all-groups.json";
import { createPopper } from "@popperjs/core";
import OpusMediaRecorder from "OpusMediaRecorder";
import { v4 as uuidV4 } from "uuid";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import vSelect from "vue-select";
import { mapState } from "vuex";
import axios from "../../../axios";
import FeatherIcon from "../../../components/FeatherIcon.vue";
import ThunderIcon from "../../../components/svg/ThunderIcon.vue";
import ChatContact from "./ChatContact.vue";
import ChatContactSkeleton from "./ChatContactSkeleton.vue";
import ChatLog from "./ChatLog.vue";
import ChatNavbar from "./ChatNavbar.vue";
import socket from "./socketAttendance";

export default {
  name: "vx-sidebar",
  components: {
    ThunderIcon,
    VsDuotalkInboxTabs,
    "twemoji-picker": TwemojiPicker,
    ChatLog,
    ChatContact,
    ChatContactSkeleton,
    ChatNavbar,
    ContactProfile,
    DynamicTitle,
    VuePerfectScrollbar,
    vSelect,
    FeatherIcon,
  },
  data() {
    return {
      chatLogKey: 1,
      quickMessageMedia: null,
      quickMessageMediaMimetype: "",
      messageFileUrl: null,
      messageFileMimetype: "",
      cloudChats: [],
      searchTemplate: "",
      inboxQuickMessagesWppAPIApprovedOnChatFiltered: [],
      active: true,
      activeContactIsClosed: false,
      alwaysactive: true,
      addConversationChat: null,
      addConversationIntention: "",
      addConversationEntity: "",
      addConversationLoading: false,
      addConversationName: "",
      addConversationPhoneCountryCode: "",
      addConversationPhone: "",
      addConversationNameDisabled: false,
      addConversationPhoneDisabled: false,
      addConversationContact: "",
      addConversationPopup: false,
      addConversationOnSearch: false,
      addConversationSearchLoading: false,
      attendanceSync: false,
      attendanceSyncStarted: null,
      conversationsNumbers: {
        waitingAttendance: 0,
        inAttendance: 0,
        inAttendancePending: 0,
        total: 0,
      },
      oprAllIntentions: false,
      clickNotClose: true,
      conversationSearchContacts: [],
      conversation: ["Abertas", "Fechadas"],
      contactsPagination: 0,
      contactListItemStart: 0,
      contactListItemEnd: 10,
      channelsConfig: [],
      chatOptions: [],
      changeDropdown: false,
      clLib: null,
      contactListHeightConfig: {},
      contactListItemHeight: 86,
      currentTabChatsLength: 0,
      dropDownKey: -1,
      emojiKeyForceRerender: 0,
      filter: 0,
      filterUnreadOnly: false,
      selectOpen: false,
      filesToUpload: [],
      filesToUploadPreview: [],
      fileToUploadPreviewIndex: 0,
      firstID: null,
      ia: {},
      isChatSidebarActive: true,
      isHidden: false,
      inputTypeBoxWithListeners: false,
      isChatPinned: false,
      inboxChannelsAvailable: [],
      inboxNewQuickMessagesFiltered: [],
      inboxIntentions: [],
      inboxQuickMessagesWppAPIApproved: [],
      inboxOperatorsList: [],
      inboxQuickMessagesFiltered: [],
      lastConversationID: undefined,
      lastID: null,
      lastTimestamp: 0,
      loadingClosed: false,
      localImageFile: null,
      loadingConversationsSpin: false,
      loadingMoreContacts: false,
      localDBTable: "inbox-unsended-messages",
      messagesListPage: 1,
      newQuickMessageVisible: false,
      notificationTitle: "",
      phaseList: [],
      placement: "top",
      proactiveChannels: [],
      replyMethod: { label: "Email", value: 0 },
      replyOptions: [""],
      showEndMessage: false,
      sendMessagesQueue: [],
      searchContact: "",
      searchQueryLocal: "",
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.7,
      },
      showWppDisconnectedActive: false,
      showScrollMessagesListToBottom: false,
      scrollMessagesListToBottom: 0,
      selectAllOperators: true,
      selectAllIntentions: true,
      socketConnOnline: true,
      socketConnOnlineCheckRoutine: () => {},
      socketContactListTimestamp: Date.now(),
      socketConversationTimestamp: Date.now(),
      socketLastPing: Date.now(),
      socketReconnect: false,
      startLivechatTimer: null,
      startTemplateConversationWPPApiAwait: false,
      searchQueryQuickMessages: "",
      selectedQuickMessage: "",
      showDropzone: false,
      showLoadMoreConversations: true,
      timerSearchQuery: null,
      timerSearchQueryContacts: null,
      totalConversations: 0,
      tabUpdate: 1,
      textareaListener: false,
      editVariablesTemplate: {},
      editVariablesTemplatesPopup: false,
      quickMessagesPopup: false,
      createQuickMessagesPopup: false,
      createQuickMessages: {
        title: "",
        intentions: [],
        entities: [],
        message: "",
      },
      createQuickMessagesOptions: {
        intentions: [],
        entities: [],
      },
      validPhone: true,
      wppTargetNotification: false,
      windowWidth: window.innerWidth,
      windowTitleInterval: null,
      isReady: false,
      chatGptSuggestionGenerated: false,
      calculateContactListHeightTimeout: null,
      conversationsLoadingSkeleton: [
        1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
        1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
      ],
      conversationsLoadingSkeletonUp: [],
      conversationsLoadingSkeletonDown: [],
      conversationsDocs: [],
      conversations: {
        getAll: () => this.conversationsDocs,
        findOne: (id) => {
          const i = this.conversationsDocs.findIndex((x) => x.id === id);
          if (i > -1) return this.conversationsDocs[i];
          return null;
        },
        update: (id, item) => {
          const i = this.conversationsDocs.findIndex((x) => x.id === id);
          const indexSameContactId = this.conversationsDocs.findIndex(
            (x) => x.contactID === id
          );

          if (i > -1) {
            const conversation = this.conversationsDocs[i];

            if (
              (this.currentOperator.acl === "operator" &&
                item.operator &&
                item.operator !== this.currentOperator._id) ||
              (this.activeTab !== 2 &&
                item.closed !== undefined &&
                item.closed === true)
            ) {
              if (item.id === this.activeChatUser) this.resetActiveChatUser();
              this.conversations.delete(id);
              return conversation;
            }

            Object.keys(item).forEach((key) => {
              if (item[key] !== undefined) {
                this.conversationsDocs[i][key] = item[key];
              }
            });
            if (item.timestamp) this.sortConversations();

            return conversation;
          } else if (indexSameContactId > -1) {
            if (
              (this.currentOperator.acl === "operator" &&
                item.operator &&
                item.operator !== this.currentOperator._id) ||
              (this.activeTab !== 2 &&
                item.closed !== undefined &&
                item.closed === true)
            ) {
              if (item.id === this.activeChatUser) this.resetActiveChatUser();
              this.conversations.delete(id);
              return conversation;
            }

            Object.keys(item).forEach((key) => {
              if (item[key] !== undefined)
                this.conversationsDocs[indexSameContactId][key] = item[key];
            });
            const conversation = this.conversationsDocs[indexSameContactId];
            if (item.timestamp) this.sortConversations();

            return conversation;
          }
        },
        create: (item) => {
          this.conversationCreate(item);
        },
        createOrUpdate: (item) => {
          this.calculateContactListHeightTimeout = null;
          const i = this.conversationsDocs.findIndex((x) => x.id === item.id);
          if (i > -1) {
            const update = JSON.parse(
              JSON.stringify(this.conversationsDocs[i])
            );
            Object.keys(item).forEach((key) => {
              if (item[key] !== undefined) update[key] = item[key];
            });
            this.conversationUpdate(i, update);
          } else this.conversationCreate(item);

          if (this.searchQuery.length > 0) this.applyFilters();
        },
        delete: (id) => {
          this.conversationDelete(id);
        },
      },
      inboxSettingsData: {},
      inboxWarningFlags: {},
      closingConversationReasons: [],
      salesSuccessReasons: [],
      salesFailureReasons: [],
      inboxSignOperator: false,
      blockCreateConversationOperator: true,
      templateData: {
        name: "",
        message: "",
        components: [],
      },
      showTemplate: false,
      mediaAudioInterval: null,
      mediaAudioRecordingMin: "00",
      mediaAudioRecordingSec: "00",
      blockWaitingServiceTab: true,
    };
  },
  computed: {
    activeUserName() {
      return this.$store.state.user.name.trim();
    },
    quickMessageMediaUrl() {
      return typeof this.quickMessageMedia === "string"
        ? this.quickMessageMedia
        : URL.createObjectURL(this.quickMessageMedia);
    },
    replyMessage() {
      return this.$store.state.chat.chatReplyMessage;
    },
    waitingAttendanceLength() {
      return this.conversations
        ? this.conversations.getAll().filter(function (item) {
            return !item.inAttendance && item.visible && !item.closed;
          }).length
        : 0;
    },
    inAttendanceLength() {
      return this.conversations
        ? this.conversations.getAll().filter(function (item) {
            return (
              item.pendingResponse &&
              item.inAttendance &&
              item.visible &&
              !item.closed
            );
          }).length
        : 0;
    },
    inboxQuickMessagesWppAPIApprovedOnChat() {
      const thisIns = this;
      let messages = [];

      this.inboxQuickMessagesWppAPIApproved.forEach((el) => {
        if (el.whatsappApiTemplates) {
          el.whatsappApiTemplates.forEach((el2) => {
            if (el2.chat === thisIns.activeContact.chatID)
              messages.push({ ...el, template: el2 });
          });
        }

        if (el.components) {
          if (el.status.toLowerCase() === "approved") {
            el.components.map((el2) => {
              if (el2.type.toLowerCase().includes("body")) {
                messages.push({ ...el, message: el2.text });
              }
            });
          }
        }
      });
      return messages;
    },
    currentTabChats() {
      const self = this;

      return this.conversations.getAll().filter(function (item) {
        const filterUnread = !self.filterUnreadOnly
          ? true
          : item.pendingResponse === true || item.postponedTo;
        return !!(
          (filterUnread &&
            !item.closed &&
            (item.visible || item.filtered) &&
            item.inAttendance &&
            self.$store.state.chat.activeTab === 1) ||
          (filterUnread &&
            !item.closed &&
            (item.visible || item.filtered) &&
            !item.inAttendance &&
            self.$store.state.chat.activeTab === 0) ||
          (item.closed &&
            (item.visible || item.filtered) &&
            self.$store.state.chat.activeTab === 2)
        );
      });
    },
    currentTabChatsPaginated() {
      return Array.isArray(this.currentTabChats) &&
        this.contactListItemStart < this.currentTabChats.length
        ? this.currentTabChats.slice(
            this.contactListItemStart,
            this.contactListItemEnd
          )
        : [];
    },
    currentAccId() {
      return this.$store.state.acc.current_acc.id;
    },
    accHasAIFeature() {
      return (
        this.$store.state.acc.current_acc.aiEnable ||
        this.currentAccId === "60ad40a8cf24431d122b2433" ||
        this.currentAccId === "608c60d25e9671ec25384820" ||
        this.currentAccId === "5f5a4806e7a7bc318ae589cc" ||
        this.currentAccId === "64e7286b799c8d04217445ee" ||
        this.currentAccId === "626a9da67edc070332477a55" ||
        this.currentAccId === "626a9d947edc07e34c4776a1" ||
        this.currentAccId === "64f774f21bb62e5a0f0ae7be" ||
        this.currentAccId === "634f2828d73776a6886df536" ||
        this.currentAccId === "638ddbce63ef447b55b5f6b1" ||
        this.currentAccId === "63286decb79ebf3b3384367e" ||
        this.currentAccId === "6298bbf57d445a5dec90e060" ||
        this.currentAccId === "62c5a4e1f45755f275bd8d1d" ||
        this.currentAccId === "62c5a4e1f45755f275bd8d1d" ||
        this.currentAccId === "623b367aadbb4d4aa6802372" ||
        this.currentAccId === "63a36fd60a079cd7b75de1ed" ||
        this.currentAccId === "63a36fe60a079c2e6d5de573" ||
        this.currentAccId === "647de082c17f5f6bc518bcca" ||
        this.currentAccId === "647de0c5f88ea8e8edbbd3dd" ||
        this.currentAccId === "647de0ddfdf35443e301ebcf" ||
        this.currentAccId === "647de0f4fdf354294f01ef36" ||
        this.currentAccId === "647de106f88ea8652cbbde82" ||
        this.currentAccId === "647de11ed44b0d4ad326c9d2" ||
        this.currentAccId === "647de131821e83a789e02735" ||
        this.currentAccId === "647de37afdf354c84502809b" ||
        this.currentAccId === "647de399d44b0d36682757c2" ||
        this.currentAccId === "647de3af7c43cdc9d2c25192" ||
        this.currentAccId === "647e12ed2e3fd754b5375ef1" ||
        this.currentAccId === "648c67528f79849e304b2205" ||
        this.currentAccId === "64a5870d3ef4914948a88910" ||
        this.currentAccId === "64a5871e997f109a046f4c4c" ||
        this.currentAccId === "6310a6c603f50d057e202d02" ||
        this.currentAccId === "6310a75f03f50d65af207d68" ||
        this.currentAccId === "63a36faa51d3cd17c1e400a3" ||
        this.currentAccId === "63ab38651b5aadfa16ab87b6" ||
        this.currentAccId === "63efc042b2d31059ad35d66e"
      );
    },
    activeUserInfo() {
      return this.$store.state.user;
    },
    activeUserImg() {
      return this.$store.state.user.img || "";
    },
    getStatusColor() {
      return (isActiveUser) => {
        const userStatus = this.getUserStatus(isActiveUser);

        if (userStatus === "online") {
          return "success";
        } else if (userStatus === "do not disturb") {
          return "danger";
        } else if (userStatus === "away") {
          return "warning";
        } else {
          return "grey";
        }
      };
    },
    chats() {
      return this.$store.getters["chat/chats"];
    },
    ...mapState("chat", [
      "teamChatOpen",
      "searchQuery",
      "currentOperator",
      "inboxOperatorsArrayStored",
      "inboxChannels",
      "activeContact",
      "currentAttendance",
      "attendance",
      "activeProfileSidebar",
      "activeChatUser",
      "userProfileId",
      "participants",
      "blob",
      "audioSource",
      "recorder",
      "activeTabName",
      "intentionsArrayStored",
      "entitiesArray",
      "allIntentions",
      "allEntities",
      "notificationCounter",
      "inboxQuickMessages",
    ]),
    channel: {
      get() {
        return this.$store.state.chat.channel;
      },
      set(val) {
        this.$store.dispatch("chat/setChannel", val);
      },
    },
    activeTab: {
      get: function () {
        return this.$store.state.chat.activeTab;
      },
      set: function (tab) {
        if (this.$refs.contactsList && this.$refs.contactsList.$el)
          this.$refs.contactsList.$el.scrollTop = 0;
        this.$store.dispatch("chat/setActiveTab", tab);
      },
    },
    typedMessage: {
      get: function () {
        const typedMessage = this.$store.state.chat.typedMessage
          ? this.$store.state.chat.typedMessage.find(
              (el) => el.id === this.activeContact.id
            )
          : false;
        return typedMessage ? typedMessage.message : "";
      },
      set: function (typedMessage) {
        if (!this.textareaListener) {
          this.textareaListener = true;
          const textarea = document.querySelector("#input-textarea-message");
          if (textarea) {
            textarea.addEventListener(
              "keypress",
              function (e) {
                let keyCode = e.charCode;
                if (keyCode === 13 && !e.shiftKey) e.preventDefault();
              },
              false
            );
          }
        }
        this.$store.dispatch("chat/setTypedMessage", {
          id: this.activeContact.id,
          message: typedMessage,
        });
      },
    },
    messageList: {
      get: function () {
        return this.$store.state.chat.messageList;
      },
      set: async function (messageList) {
        if (messageList.length > 0) {
          const unsendedMessage = await this.$db
            .collection(this.localDBTable)
            .doc(this.activeContact.id)
            .get();
          if (
            unsendedMessage &&
            unsendedMessage.messages &&
            Array.isArray(unsendedMessage.messages)
          ) {
            for (let i = 0; i < unsendedMessage.messages.length; i += 1)
              if (
                messageList.find(
                  (el) => el.uid === unsendedMessage.messages[i].uid
                )
              )
                this.removeUnsendedMessage(
                  this.activeContact.id,
                  unsendedMessage.messages[i].uid
                );
            messageList = messageList.concat(unsendedMessage.messages);
            messageList.sort((a, b) => {
              if (typeof a.timestamp === "string")
                a.timestamp = new Date(a.timestamp).getTime();
              if (typeof b.timestamp === "string")
                b.timestamp = new Date(b.timestamp).getTime();
              return a.timestamp - b.timestamp;
            });
          }
        }
        this.$store.dispatch("chat/setMessageList", messageList);
      },
    },
    intentionsArray: {
      get: function () {
        return this.$store.state.chat.intentionsArray;
      },
      set: function (intentionsArray) {
        this.$store.dispatch("chat/setIntentionsArray", intentionsArray);
      },
    },
    isActiveChatUser() {
      return (userId) => {
        return userId === this.activeChatUser;
      };
    },
    filterIsActive() {
      return this.intentionsArray.length !==
        this.intentionsArrayStored.length ||
        this.inboxOperatorsArray.length !==
          this.inboxOperatorsArrayStored.length
        ? "filled"
        : "border";
    },
    inboxOperatorsArray: {
      get: function () {
        return this.$store.state.chat.inboxOperatorsArray;
      },
      set: function (inboxOperatorsArray) {
        this.$store.dispatch(
          "chat/setInboxOperatorsArray",
          inboxOperatorsArray
        );
      },
    },
    currentOperatorIsManager() {
      if (this.currentOperator.acl === "admin") return true;
      else return this.currentOperator.acl === "manager";
    },
    emojiDataAll() {
      if (EmojiAllData && EmojiAllData[1] && EmojiAllData[1].emojiList)
        EmojiAllData[1].emojiList.splice("16", 1);
      return EmojiAllData;
    },
    emojiGroups() {
      return EmojiGroups;
    },
    emojiPickerSize() {
      let size = window.innerWidth * 0.2;
      if (window.innerWidth < 1300) size = 300;
      return size;
    },
    operatorIntentions() {
      let intentions = [];
      const flowIntentions = [];
      const flowIntentionsArr = this.chatOptions.map(
        (el) => el.data.intentions
      );
      flowIntentionsArr.forEach((elArr) => {
        elArr.forEach((el) => {
          flowIntentions.push(el.replace("#", ""));
        });
      });
      if (
        this.addConversationChat &&
        this.addConversationChat.data &&
        this.addConversationChat.data.intentions
      ) {
        this.intentionsArrayStored.forEach((el) => {
          if (
            (this.addConversationChat.data.intentions.indexOf(`#${el}`) > -1 ||
              flowIntentions.indexOf(el) === -1) &&
            intentions.indexOf(el) === -1
          )
            intentions.push(el);
        });

        if (this.addConversationChat.data.isAssistant) {
          intentions = [
            ...this.addConversationChat.data.intentions,
            ...intentions,
          ];
        }
      }

      if (
        (this.intentionsArrayStored.indexOf("") > -1 ||
          this.intentionsArrayStored.indexOf("outros") > -1) &&
        intentions.indexOf("outros") === -1
      )
        intentions.push("outros");
      return intentions;
    },
    operatorEntities() {
      let entities = [];
      const flowEntities = [];
      const flowEntitiesArr = this.chatOptions.map((el) => el.data.keyEntities);
      flowEntitiesArr.forEach((elArr) => {
        elArr.forEach((el) => {
          flowEntities.push(el);
        });
      });
      if (
        this.addConversationChat &&
        this.addConversationChat.data &&
        this.addConversationChat.data.keyEntities
      )
        this.entitiesArray.forEach((el) => {
          if (
            (this.addConversationChat.data.keyEntities.indexOf(el) > -1 ||
              flowEntities.indexOf(el) === -1) &&
            entities.indexOf(el) === -1
          )
            entities.push(el);
        });
      return entities;
    },
    filterTemplateQuickMessages() {
      if (this.searchQueryQuickMessages !== "") {
        return this.inboxQuickMessages.filter(
          (i) =>
            i.message
              .toLowerCase()
              .includes(this.searchQueryQuickMessages.toLowerCase()) ||
            i.title
              .toLowerCase()
              .includes(this.searchQueryQuickMessages.toLowerCase())
        );
      } else {
        return this.inboxQuickMessages;
      }
    },
  },
  watch: {
    channel() {
      this.getQuickMessagesWppApi();
    },
    searchTemplate(val) {
      this.handleSearchTemplates(val);
    },
    waitingAttendanceLength() {
      this.tabUpdate += 1;
    },
    inAttendanceLength() {
      this.tabUpdate += 1;
    },
    loadingConversationsSpin(val) {
      if (val)
        setTimeout(() => {
          this.loadingConversationsSpin = false;
        }, 120000);
    },
    addConversationPopup: function (val) {
      this.validPhone = true;
      if (!val) {
        this.addConversationLoading = false;
        this.addConversationIntention = "";
        this.addConversationEntity = "";
        this.addConversationContact = "";
        this.addConversationName = "";
        this.addConversationNameDisabled = false;
        this.addConversationPhone = "";
        this.addConversationPhoneDisabled = false;
        this.addConversationPhoneCountryCode = "";
      }
    },
    addConversationContact: function (val) {
      this.addConversationName = val && val.name ? val.name : "";
      this.addConversationNameDisabled = !!(val && val.name);
      this.addConversationPhone = val && val.phone ? val.phone : "";
      this.addConversationPhoneDisabled = !!(val && val.name);

      const chatIndex = this.chatOptions.findIndex((el) => el === val.chat);
      if (chatIndex > -1)
        this.addConversationChat = {
          label: this.chatOptions[chatIndex].label,
          value: this.chatOptions[chatIndex].value,
          data: this.chatOptions[chatIndex].data,
        };
      else if (this.chatOptions.length > 1)
        this.addConversationChat = { label: "", value: "" };
    },
    activeTab: async function (val, oldVal) {
      this.showLoadMoreConversations = true;
      this.pushRouteQueryParams(
        { acc: this.$store.state.acc.current_acc.id, activeTab: val },
        true
      );
      if (oldVal === 2) {
        this.$vs.loading.close();
      }

      this.resetActiveChatUser();
      if (val === 2) {
        this.showLoadMoreConversations = true;
        this.initClosedTab();
      }

      this.calculateContactListHeight(true, true);
    },
    activeProfileSidebar: function (val) {
      if (val) this.$refs.chatlog.style = "padding-right: 349px;";
      else this.$refs.chatlog.style = "";
    },
    activeChatUser: function (val) {
      this.chatLogKey++;
      if (val) {
        const contact = this.conversations.findOne(val);
        if (contact)
          this.$store.dispatch("chat/addOnCurrentAttendance", contact);
      }
    },
    filterUnreadOnly() {
      if (this.$refs.contactsList) this.$refs.contactsList.scrollTop = 0;
      this.calculateContactListHeight(true, true);
    },
    intentionsArray() {
      this.$vs.loading();

      setTimeout(() => {
        this.applyFilters();
      }, 500);
    },
    inboxOperatorsArray() {
      this.$vs.loading();

      setTimeout(() => {
        this.applyFilters();
      }, 500);
    },
    searchQuery(val) {
      setTimeout(async () => {
        if (this.conversations.getAll().length > 998) {
          if (val && val.length > 0) await this.updateConversations(2);
        }
        this.applyFilters();
      }, 500);
    },
    selectAllIntentions(checked) {
      if (checked)
        this.$store.dispatch(
          "chat/setIntentionsArray",
          this.intentionsArrayStored
        );
      else this.$store.dispatch("chat/setIntentionsArray", []);
    },
    selectAllOperators(checked) {
      if (checked)
        this.$store.dispatch(
          "chat/setInboxOperatorsArray",
          this.inboxOperatorsArrayStored.map((operator) => operator.value)
        );
      else this.$store.dispatch("chat/setInboxOperatorsArray", []);
    },
    notificationCounter(val) {
      this.changeWindowTitle(val);
    },
    activeContact(newVal, oldVal) {
      if (
        !oldVal.closed &&
        oldVal.sessionID &&
        (!newVal || newVal.sessionID !== oldVal.sessionID)
      )
        socket.emit("leave conversation", {
          sessionID: oldVal.sessionID,
        });
      this.filesToUpload = [];
      this.filesToUploadPreview = [];
      this.fileToUploadPreviewIndex = 0;
      this.activeContactIsClosed = !!newVal.closed;
    },
    filesToUpload(arr) {
      const chatbtn = document.getElementById("teamChatFrame");
      if (arr.length === 0 && chatbtn) chatbtn.style.display = "block";
      else if (chatbtn) chatbtn.style.display = "none";
    },
    selectedQuickMessage: function (val) {
      if (val) {
        this.selectQuickMessage(val);
        this.dropDownKey = Math.random();
        this.selectedQuickMessage = "";
        this.searchQueryQuickMessages = "";
      }
    },
    typedMessage() {
      if (this.typedMessage.length === 0) {
        this.chatGptSuggestionGenerated = false;
      }
    },
    searchQuickMessage() {
      this.filterQuickMessages();
    },
  },
  beforeCreate() {
    window.onbeforeunload = function (event) {
      event.preventDefault();
      event.returneValue = "";
    };
  },
  async created() {
    this.$store.dispatch("chat/setNotificationCounter", 0);
    this.getQuickMessages();
    axios
      .get("/p/chat/inbox/phases", {
        params: { acc: this.$store.state.acc.current_acc.id },
      })
      .then((response) => {
        this.phaseList = response.data.data;
      })
      .catch(() => {});

    /* end socket */
    this.getChats();
    this.$nextTick(() => {
      window.addEventListener("resize", this.handleWindowResize);
    });
    this.setSidebarWidth();
  },
  mounted() {
    if (!this.isReady) {
      this.conversationsLoadingSkeletonUp = this.conversationsLoadingSkeleton;
    }
    const thisIns = this;
    const contactListscrollArea = document.getElementById(
      "contactListscrollArea"
    );

    contactListscrollArea.addEventListener("scroll", function () {
      thisIns.calculateContactListHeightScroll(
        document.querySelectorAll(".contact-list-item")
      );
    });

    document.body.style = "overflow: hidden;";
    //Center archive tab icon
    setTimeout(() => {
      if (this.$refs.tabArchive)
        this.$refs.tabArchive.$refs.ul
          .getElementsByTagName("li")[2]
          .getElementsByTagName("button")[0].style =
          "display: flex; align-items: center; justify-content: center";
    }, 100);

    /*setInterval(() => {
      if (!document.hidden) this.getQuickMessages();
    }, 60000);*/

    this.socketConnOnlineCheckRoutine = async () => {
      if (!navigator.onLine && this.socketConnOnline) {
        try {
          const conStatusHttp = await axios.get("/ping", { timeout: 1500 });
          if (conStatusHttp.status !== 200) this.socketConnOnline = false;
          else this.socketConnOnline = navigator.onLine;
        } catch (err) {
          this.socketConnOnline = false;
        }
        socket.disconnect();
      } else if (navigator.onLine && !this.socketConnOnline) {
        socket.connect();
        this.socketConnOnline = true;
      }
    };

    window.addEventListener("online", this.socketConnOnlineCheckRoutine);
    window.addEventListener("offline", this.socketConnOnlineCheckRoutine);

    //STARTUP INBOX ATTENDANCE
    this.initilizeInbox();

    if (!socket.connected) {
      this.loadingConversationsSpin = true;
    }
    if (this.$refs.chatlog !== undefined) {
      if (this.$store.state.chat.activeProfileSidebar)
        this.$refs.chatlog.style = "padding-right: 349px;";
      else this.$refs.chatlog.style = "";
    }

    this.toggleChatSidebar(true);
    let lastTarget = null;
    window.addEventListener("dragover", (event) => {
      event.preventDefault();
    });

    window.addEventListener("dragenter", (event) => {
      if (this.channelAcceptMedia(this.activeContact.channel)) {
        lastTarget = event.target;

        this.showDropzone = true;
      }
    });

    window.addEventListener("dragleave", (event) => {
      if (this.channelAcceptMedia(this.activeContact.channel)) {
        event.preventDefault();
        if (event.target === lastTarget || event.target === document) {
          this.showDropzone = false;
        }
      }
    });

    window.addEventListener("drop", this.onDrop);
  },
  beforeDestroy: function () {
    if (localStorage.getItem("startConversation")) {
      localStorage.removeItem("startConversation");
    }

    window.removeEventListener("resize", this.handleWindowResize);
    this.$store.dispatch("chat/reset");
    this.closeAttendance(true);
    clearInterval(this.socketConnOnlineCheckRoutine);
    clearInterval(window.inboxSocketPing);
    window.inboxSocketPing = null;
  },
  methods: {
    chatLogScrollTop(scrollTop) {
      this.showScrollMessagesListToBottom = true;
      this.$refs.chatLog.scrollTop = scrollTop;
    },
    onChatLogScroll({ target: { scrollTop } }) {
      if (scrollTop <= 0) this.messagesListPage += 1;
    },
    handleRecording() {
      window.analytics.track(
        "Enviar audio",
        {},
        { groupId: this.$store.state.acc.current_acc.id }
      );
      !this.$store.state.chat.recorder ||
      this.$store.state.chat.recorder.state !== "recording"
        ? this.startRecording()
        : this.stopRecording();
    },
    removeMessageFileUrl() {
      this.messageFileUrl = null;
      this.messageFileMimetype = "";
    },
    quickMessagePreviewFile(e) {
      if (!e.target.files.length) {
        this.quickMessageMedia = null;
        return;
      }

      this.quickMessageMedia = e.target.files[0];

      this.quickMessageMediaMimetype = this.quickMessageMedia.type || "";
    },
    postponeConversation({ postponedTo }) {
      this.$vs.loading();
      socket.emit(
        "postpone conversation",
        {
          postponedTo,
          contactSessionID: this.activeContact.contactSessionID,
          operatorID: this.currentOperator._id,
        },
        () => {
          this.$vs.loading.close();
        }
      );
    },
    handleSearchTemplates(search) {
      if (search.length > 0) {
        this.inboxQuickMessagesWppAPIApprovedOnChatFiltered =
          this.inboxQuickMessagesWppAPIApprovedOnChat.filter((e) => {
            if (e.title.toLowerCase().includes(search)) {
              return true;
            } else return e.message.toLowerCase().includes(search);
          });
      } else {
        this.inboxQuickMessagesWppAPIApprovedOnChatFiltered =
          this.inboxQuickMessagesWppAPIApprovedOnChat;
      }
    },
    async closeReplyMessage() {
      await this.$store.dispatch("chat/setChatReplyMessage", null);
    },
    onReplyMessage() {
      this.$refs.inputTextareaMessage.focus();
    },
    onReaction(msgId, emoji) {
      if (!msgId) return;

      const message = this.messageList.find((e) => e.id === msgId);

      this.$store.dispatch("chat/updateMessageOnMessageList", {
        _id: message._id || -1,
        messageID: message.id,
        payload: {
          data: message.data,
          reactions: {
            ...message.reactions,
            operator: emoji,
          },
        },
      });

      socket.emit("message reaction", {
        messageID: msgId,
        reaction: emoji,
        chatID: this.activeContact.chatID,
        conversationID: this.activeContact.contactSessionID,
        sessionID: this.activeContact.sessionID,
      });
    },
    selectNewQuickMessage(val) {
      if (val) {
        this.selectQuickMessage(val);
        this.newQuickMessageVisible = false;
        this.$refs.inputTextareaMessage.focus();
      }
    },
    inspectBar(e) {
      if (!this.typedMessage || this.typedMessage.trim() === "") {
        this.$vs.notify({
          title: this.$t("inbox.emptyMessage"),
          text: this.$t("inbox.emptyMessageInput"),
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
          position: "top-right",
          time: 4000,
        });
        return;
      }
      if (this.typedMessage.trim() <= 0) {
        this.messageFileUrl = null;
        this.messageFileMimetype = "";
      }

      let inputValue = this.typedMessage;
      let key = e.key.toLowerCase();
      let search = inputValue.replaceAll("/", "").toLowerCase();

      if (inputValue[0] !== "/") {
        this.newQuickMessageVisible = false;

        if (key === "enter") this.sendMsg(e);

        return;
      }

      if (inputValue[0] !== "/" && key !== "/") return;

      this.inboxNewQuickMessagesFiltered = this.inboxQuickMessages;
      this.inboxQuickMessagesWppAPIApprovedOnChatFiltered =
        this.inboxQuickMessagesWppAPIApprovedOnChat;
      setTimeout(() => {
        this.newQuickMessageVisible = true;
      }, 30);

      if (search.length > 0) {
        this.inboxNewQuickMessagesFiltered =
          this.inboxNewQuickMessagesFiltered.filter((e) => {
            if (e.title.toLowerCase().includes(search)) {
              return true;
            } else return e.message.toLowerCase().includes(search);
          });
        this.inboxQuickMessagesWppAPIApprovedOnChatFiltered =
          this.inboxQuickMessagesWppAPIApprovedOnChatFiltered.filter((e) => {
            if (e.title.toLowerCase().includes(search)) {
              return true;
            } else return e.message.toLowerCase().includes(search);
          });
      }
    },
    addConversationSearchContacts(searchQuery = null) {
      this.addConversationOnSearch = searchQuery.length > 0;
      this.conversationSearchContacts = [];
      if (searchQuery.length > 2) {
        this.addConversationSearchLoading = true;
        if (this.timerSearchQueryContacts) {
          clearTimeout(this.timerSearchQueryContacts);
          this.timerSearchQueryContacts = null;
        }
        this.timerSearchQueryContacts = setTimeout(() => {
          this.addConversationSearchLoading = true;

          const self = this;

          this.$http
            .get("/p/chat/contacts", {
              params: {
                acc: this.$store.state.acc.current_acc.id,
                searchQuery,
              },
            })
            .then((response) => {
              self.addConversationSearchLoading = false;
              self.conversationSearchContacts = response.data.data.map(
                (contact) => {
                  return {
                    ...contact,
                    search: `${contact.name}${contact.name
                      .normalize("NFD")
                      .replace(/[\u0300-\u036f]/g, "")}${contact.phone}${
                      contact.email ? contact.email : ""
                    }`,
                  };
                }
              );
              let startConversation = localStorage.getItem("startConversation");
              if (startConversation) {
                startConversation = JSON.parse(startConversation);
                this.addConversationContact =
                  this.conversationSearchContacts.find(
                    (e) =>
                      e.phone ===
                      startConversation.phone.replace(/[^A-Z0-9]+/gi, "")
                  ) || "";
              }
              localStorage.removeItem("startConversation");
            })
            .catch(() => {
              self.addConversationSearchLoading = false;
            });
        }, 800);
      } else self.addConversationSearchLoading = false;
    },
    async addConversation() {
      window.analytics.track(
        "Criar conversa",
        {},
        { groupId: this.$store.state.acc.current_acc.id }
      );
      this.addConversationLoading = true;
      const name =
        this.addConversationName && this.addConversationName.length > 0
          ? this.addConversationName
              .trim()
              .replace(/\t/g, "")
              .replace(/\r/g, "")
              .replace(/\n/g, "")
          : this.addConversationName;
      const nationalPhone = String(
        (this.addConversationPhone && this.addConversationPhone.length > 0
          ? this.addConversationPhone
              .trim()
              .replace(/\t/g, "")
              .replace(/\r/g, "")
              .replace(/\n/g, "")
          : this.addConversationPhone
        ).replace(/\D/g, "")
      );
      const phone = `${this.addConversationPhoneCountryCode}${nationalPhone}`;

      const acc = this.currentAccId;
      const intention = this.addConversationIntention;
      const entity = this.addConversationEntity;
      const operatorId = this.currentOperator._id;
      const validPhone = this.validPhone;
      const chat = this.addConversationChat
        ? this.addConversationChat.value
        : null;

      if (validPhone && intention && name && phone) {
        await this.$http
          .post("/p/chat/conversation", {
            name,
            phone,
            acc,
            intention,
            entity,
            operatorId,
            chat,
          })
          .then(async (response) => {
            this.resetActiveChatUser();
            this.toggleChatSidebar(false);
            if (response.data.data) {
              const contact = await this.createNewConversation(
                response.data.data
              );
              this.removeConversationContact();

              await this.$store.dispatch("chat/setActiveTab", 1);

              if (contact) {
                this.updateActiveChatUser(contact, contact.id);
                this.addConversationPopup = false;
                this.$vs.notify({
                  title: this.$t("Success"),
                  text: this.$t("AddConversationSuccess"),
                  iconPack: "feather",
                  icon: "icon-check",
                  color: "success",
                  position: "top-right",
                  time: 4000,
                });
              }
            }
          })
          .catch(async (res) => {
            this.addConversationLoading = false;
            const { message, data } = JSON.parse(res.request.responseText);
            if (message === "Already exists") {
              const foundedConversation = this.conversations.findOne(data.id);

              if (foundedConversation) {
                await this.$store.dispatch(
                  "chat/setActiveTab",
                  foundedConversation.inAttendance ? 1 : 0
                );
                this.updateActiveChatUser(
                  foundedConversation,
                  foundedConversation.id
                );
                this.addConversationPopup = false;
                return;
              } else {
                await this.updateConversations(2, data.id);
                this.applyFilters();
              }

              const intentionText =
                this.$t("AtDepartment") + " " + data.intention ||
                this.$t("Others");

              const operatorText = data.operator
                ? `${this.$t("AndAttendant")} ${data.operator}`
                : "";

              this.$vs.notify({
                title: this.$t("UnexpectedError"),
                text:
                  this.$t("AddConversationAlreadyExists") +
                  ` ${intentionText} ${operatorText}`,
                iconPack: "feather",
                icon: "icon-alert-circle",
                color: "warning",
                position: "top-right",
                time: 4000,
              });
            } else if (message === "Incorrect data") {
              this.$vs.notify({
                title: this.$t("Error"),
                text: this.$t("VerifyDataAndTryAgain"),
                iconPack: "feather",
                icon: "icon-alert-circle",
                color: "danger",
                position: "top-right",
                time: 4000,
              });
            } else if (message === "Blocked contact") {
              this.$vs.notify({
                title: this.$t("Error"),
                text: "Contato bloqueado.",
                iconPack: "feather",
                icon: "icon-user-x",
                color: "danger",
                position: "top-right",
                time: 4000,
              });
            } else {
              this.$vs.notify({
                title: this.$t("Error"),
                text: "Erro",
                iconPack: "feather",
                icon: "icon-alert-circle",
                color: "danger",
                position: "top-right",
                time: 4000,
              });
            }
            this.addConversationLoading = false;
          });
      } else {
        this.addConversationLoading = false;
        this.$vs.notify({
          time: 5000,
          title: this.$t("Error"),
          text: this.$t("VerifyDataAndTryAgain"),
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
          position: "top-right",
        });
      }
    },
    async applyFilters(filterClosed = false) {
      this.showLoadMoreConversations = true;
      if (this.$refs.contactsList && this.$refs.contactsList.$el)
        this.$refs.contactsList.$el.scrollTop = 0;
      let searchQuery = this.searchQuery;
      const intentionsArray = this.intentionsArray;
      const inboxOperatorsArray = this.inboxOperatorsArray;
      const conversations = this.conversations.getAll();

      const treatString = (str) => {
        if (str && typeof str === "string") {
          str = str.toLowerCase();
          str = str.replace(/[àáâãä]/g, "a");
          str = str.replace(/[èéêẽë]/g, "e");
          str = str.replace(/[ìíîĩï]/g, "i");
          str = str.replace(/[òóôõö]/g, "o");
          str = str.replace(/[ùúûũü]/g, "u");
          str = str.replace(/[ç]/g, "c");
        }
        return str || "";
      };

      searchQuery = treatString(searchQuery);

      const re = new RegExp(`.*${searchQuery}.*`);

      const searchTags = (arr) => {
        return arr && typeof arr === "object" && Array.isArray(arr)
          ? arr.find(
              (el) =>
                re.test(treatString(el.value)) || re.test(treatString(el.label))
            ) !== undefined
          : false;
      };
      const searchTagsContact = (arr) => {
        return arr && typeof arr === "object" && Array.isArray(arr)
          ? arr.find(
              (el) =>
                re.test(treatString(el.value)) || re.test(treatString(el.label))
            ) !== undefined
          : false;
      };

      if (this.$store.state.chat.activeTab === 2 && !filterClosed)
        if (searchQuery.length > 0)
          await this.loadMoreClosedConversations(true, true);

      conversations.forEach((chatContact) => {
        let chatContactIsOnIntentionsFilter = true;
        // Apply intentions filter
        if (intentionsArray.length !== this.intentionsArrayStored.length) {
          if (
            chatContact.intentions.length === 0 &&
            !intentionsArray.includes("outros")
          ) {
            chatContactIsOnIntentionsFilter = false;
          } else if (
            chatContact.intentions.length !== 0 &&
            chatContact.intentions.filter((intention) =>
              intentionsArray.includes(intention)
            ).length === 0
          ) {
            chatContactIsOnIntentionsFilter = false;
          }
        }

        let chatContactIsOnOperatorsFilter = true;
        // Apply operators filter
        if (
          inboxOperatorsArray.length !== this.inboxOperatorsArrayStored.length
        ) {
          if (
            chatContact.operator !== "" &&
            !inboxOperatorsArray.includes(chatContact.operator)
          ) {
            chatContactIsOnOperatorsFilter = false;
          } else if (
            chatContact.operator === "" &&
            !inboxOperatorsArray.includes("")
          ) {
            chatContactIsOnOperatorsFilter = false;
          }
        }

        // Apply search filter
        if (
          chatContactIsOnIntentionsFilter &&
          chatContactIsOnOperatorsFilter &&
          ((chatContact.name && re.test(treatString(chatContact.name))) ||
            (chatContact.email && re.test(treatString(chatContact.email))) ||
            (chatContact.phone && re.test(treatString(chatContact.phone))) ||
            (chatContact.tags && searchTags(chatContact.tags)) ||
            (chatContact.tagsContact &&
              searchTagsContact(chatContact.tagsContact)))
        ) {
          this.conversations.update(chatContact.id, { visible: true });
        } else this.conversations.update(chatContact.id, { visible: false });
      });

      this.calculateContactListHeight(true, true);
      this.$vs.loading.close();
    },
    b64toUrl(b64Data, contentType, sliceSize) {
      contentType = contentType || "";
      sliceSize = sliceSize || 512;

      var byteCharacters = atob(b64Data);
      var byteArrays = [];

      for (
        var offset = 0;
        offset < byteCharacters.length;
        offset += sliceSize
      ) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);
        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      var blob = new Blob(byteArrays, { type: contentType });
      return URL.createObjectURL(blob);
    },
    calculateContactListHeight(reload, resetPositions = false) {
      if (resetPositions) {
        this.contactListItemStart = 0;
        this.contactListItemEnd = 10;
      }
      if (!this.contactListHeightConfig.itensPositions)
        this.contactListHeightConfig.itensPositions = [];

      this.contactListHeightConfig.totalContacts =
        this.conversations.getAll().length;

      this.contactListHeightConfig.totalContactsTab =
        this.currentTabChats.length;
      const totalContactsTab =
        this.contactListHeightConfig.totalContactsTab ||
        this.currentTabChats.length;

      const contactListItem = document.querySelectorAll(".contact-list-item");
      const contactListContentArea = document.getElementById(
        "contactListContentArea"
      );
      const contactListscrollArea = document.getElementById(
        "contactListscrollArea"
      );
      if (contactListscrollArea && resetPositions) {
        contactListscrollArea.scrollTo({ top: 0 });
      }

      if (
        !contactListItem ||
        !contactListItem[0] ||
        !contactListContentArea ||
        !contactListscrollArea
      )
        return;

      const contactListItemHeight = this.contactListItemHeight;

      const itemsPerPage =
        this.contactListHeightConfig.itemsPerPage ||
        Math.ceil(contactListscrollArea.clientHeight / contactListItemHeight) *
          2;
      if (Number.isSafeInteger(itemsPerPage)) {
        this.contactListHeightConfig.itemsPerPage = itemsPerPage;

        if (reload) this.contactListItemEnd = itemsPerPage;

        if (totalContactsTab <= itemsPerPage) return;
      }
      this.contactListHeightConfig.pageItensHeight =
        itemsPerPage * contactListItemHeight;
    },
    calculateContactListHeightScroll(contactListItem) {
      const contactListscrollArea = document.getElementById(
        "contactListscrollArea"
      );

      if (!contactListscrollArea || !contactListItem) return;
      if (!this.contactListHeightConfig.itemsPerPage)
        this.calculateContactListHeight();

      const contactListItemHeight = this.contactListItemHeight;
      const totalContactsTab = this.currentTabChats.length;
      const itemsPerPage = this.contactListHeightConfig.itemsPerPage;

      if (totalContactsTab <= itemsPerPage) return;
      //SCROLL HANDLER
      const scrollTop = contactListscrollArea.scrollTop;

      if (!this.contactListHeightConfig.lastLoad)
        this.contactListHeightConfig.lastLoad = 0;

      let first = Math.floor(scrollTop / contactListItemHeight);
      let last = Math.floor(scrollTop / contactListItemHeight) + itemsPerPage;
      if (last - first < itemsPerPage || last + itemsPerPage > totalContactsTab)
        last = totalContactsTab;

      if (Number.isSafeInteger(first) && first < totalContactsTab && first >= 0)
        this.contactListItemStart = first;
      if (
        Number.isSafeInteger(last) &&
        last <= totalContactsTab &&
        last >= itemsPerPage
      )
        this.contactListItemEnd = last;

      this.contactListHeightConfig.lastScrollTop =
        contactListscrollArea.scrollTop <= 0
          ? 0
          : contactListscrollArea.scrollTop;
      //END SCROLL HANDER
    },
    changeWindowTitle(notificationCounter) {
      if (this.windowTitleInterval) {
        clearInterval(this.windowTitleInterval);
        this.windowTitleInterval = null;
      }

      if (notificationCounter) {
        const favicon = document.getElementById("favicon");
        favicon.href = "/favicon_alert.ico";
        let showNotification = false;
        this.windowTitleInterval = setInterval(() => {
          if (showNotification) {
            this.notificationTitle = "";
            showNotification = false;
          } else {
            this.notificationTitle = `(${notificationCounter} 💬) - `;
            showNotification = true;
          }
        }, 1200);
      } else {
        const favicon = document.getElementById("favicon");
        favicon.href = "/favicon.ico";
        this.notificationTitle = "";
      }
    },
    channelAcceptMedia(channel) {
      return this.channelsConfig.find(
        (el) => el.name === channel && el.mediaAvailable
      );
    },
    channelMediaData(channel) {
      return this.channelsConfig.find((el) => el.name === channel);
    },
    channelAddFileOnClick() {
      window.analytics.track(
        "Enviar anexo",
        {},
        { groupId: this.$store.state.acc.current_acc.id }
      );
      this.$refs.addFile.click();
    },
    channelAddEmoteOnClick() {
      window.analytics.track(
        "Enviar emoji",
        {},
        { groupId: this.$store.state.acc.current_acc.id }
      );
    },
    channelMediaDataSizeLimitMB(channel, mediaType) {
      const config = this.channelsConfig.find((el) => el.name === channel);
      if (config && config.mediaAcceptedTypesSizeLimitMB)
        if (config.mediaAcceptedTypesSizeLimitMB[mediaType])
          return config.mediaAcceptedTypesSizeLimitMB[mediaType];
        else if (config.mediaAcceptedTypesSizeLimitMB["file"])
          return config.mediaAcceptedTypesSizeLimitMB["file"];
      return 5;
    },
    cleanMessageInput() {
      clearInterval(this.mediaAudioInterval);
      this.mediaAudioRecordingMin = "00";
      this.mediaAudioRecordingSec = "00";
      this.$store.dispatch("chat/setTypedMessage", {
        id: this.activeContact.id,
        message: "",
      });
      this.$store.dispatch("chat/setAudioSource", null);
      this.$store.dispatch("chat/setBlob", null);
      this.filesToUpload = [];
      this.filesToUploadPreview = [];
      this.fileToUploadPreviewIndex = 0;
      this.messageFileUrl = null;
      this.messageFileMimetype = "";
      this.$refs.chatLogPS.$el.scrollTop = this.$refs.chatLog.scrollHeight;
    },
    async closeAttendance(closeSocket) {
      this.showLoadMoreConversations = true;
      await this.$store.dispatch("chat/setSearchQuery", "");
      this.searchQueryLocal = "";
      await this.$store.dispatch(
        "chat/setIntentionsArray",
        this.intentionsArrayStored
      );
      await this.$store.dispatch(
        "chat/setInboxOperatorsArray",
        this.inboxOperatorsArrayStored.map((operator) => operator.value)
      );
      if (closeSocket) {
        socket.removeAllListeners();
        if (socket.connected) socket.disconnect();
        socket.close();
      }
    },
    closeConversation(reason) {
      if (this.activeContact.closed) return;

      let showingLoading = true;
      const thisIns = this;
      this.totalConversations = this.totalConversations - 1;
      this.$vs.loading();

      socket.emit(
        "close conversation",
        {
          sessionID: this.activeContact.sessionID,
          chatID: this.activeContact.chatID,
          conversationID: this.activeContact.contactSessionID,
          userID: this.currentOperator._id,
          reason: reason.name,
          reasonType: reason.id === 1 ? 1 : reason.id === 3 ? 0 : 2,
          saleValue: reason.closingReasonSaleValue
            ? reason.closingReasonSaleValue
            : null,
          reasonLoss: reason.id === 2 ? reason.subReasons.name : null,
          customValue: reason.saleCustomValue || reason.subReasons.name,
          survey: reason.typeSearch,
        },
        (response) => {
          showingLoading = false;
          this.$vs.loading.close();
          const id = this.activeContact.id;
          if (this.conversations.findOne(id)) {
            this.totalConversations = this.totalConversations - 1;
            this.conversations.delete(id);
            this.resetActiveChatUser();
          }

          if (response.status === "ok") {
            this.$vs.notify({
              title: this.$t("Success"),
              text: this.$t("CloseConversationSuccess"),
              iconPack: "feather",
              icon: "icon-check",
              color: "success",
              position: "top-right",
              time: 4000,
            });
          } else
            thisIns.$vs.notify({
              title: thisIns.$t("Error"),
              text: thisIns.$t("ErrorOnCloseConversation"),
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger",
              position: "top-right",
              time: 4000,
            });
        }
      );
      setTimeout(function () {
        if (showingLoading) {
          thisIns.$vs.loading.close();
          thisIns.$vs.notify({
            title: thisIns.$t("Error"),
            text: thisIns.$t("ErrorOnCloseConversation"),
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "warning",
            position: "top-right",
            time: 4000,
          });
        }
      }, 6000);
    },
    countIntentionConversations(intention) {
      let count = 0;
      this.conversations.getAll().forEach((chatContact) => {
        if (
          chatContact.intentions &&
          chatContact.intentions.find(
            (intentionChatContact) => intentionChatContact === intention
          )
        ) {
          count++;
        } else if (
          (!chatContact.intentions || chatContact.intentions.length === 0) &&
          intention === "outros"
        ) {
          count++;
        }
      });
      return count;
    },
    conversationCreate(conversation) {
      this.conversationsDocs.push(conversation);
      if (this.isReady) {
        this.sortConversations();
      }
    },
    conversationUpdate(i, conversation) {
      this.conversationsDocs[i] = conversation;
    },
    conversationDelete(id) {
      const i = this.conversationsDocs.findIndex((x) => x.id === id);
      if (i > -1) this.conversationsDocs.splice(i, 1);
    },
    countOperatorConversations(operator) {
      let count = 0;
      this.conversations.getAll().forEach((chatContact) => {
        if (chatContact.operator) {
          if (chatContact.operator === operator) count++;
          else if (
            chatContact.operator.length === 0 &&
            operator === "Sem operador"
          )
            count++;
        }
      });
      return count;
    },
    async createNewConversation(conversation) {
      let element = this.treatConversation(conversation);
      if (!this.conversations.findOne(element.id)) {
        this.conversations.create(element);
        this.applyFilters();
        this.playAudio(newConversationNotification);
        return element;
      }
    },
    dataURLToBlob(dataURL) {
      let BASE64_MARKER = ";base64,";
      if (dataURL.indexOf(BASE64_MARKER) === -1) {
        const parts = dataURL.split(",");
        const contentType = parts[0].split(":")[1];
        const raw = parts[1];

        return new Blob([raw], { type: contentType });
      }

      const parts = dataURL.split(BASE64_MARKER);
      const contentType = parts[0].split(":")[1];
      const raw = window.atob(parts[1]);
      const rawLength = raw.length;

      let uInt8Array = new Uint8Array(rawLength);

      for (let i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
      }

      return new Blob([uInt8Array], { type: contentType });
    },
    downloadMessageFile(status, message) {
      this.$store.dispatch("chat/updateMessageOnMessageList", {
        _id: message._id ? message._id : -1,
        messageID: message.id,
        payload: { data: { ...message.data, fileStatus: status } },
      });

      axios({
        method: "post",
        url: `/p/chat/downloadMessageFile`,
        data: {
          getURL: true,
          message_id: message._id,
          messageID: message.id,
          inboxConversationId:
            this.$store.state.chat.activeContact.contactSessionID,
          sessionID: this.$store.state.chat.activeContact.sessionID,
          chatID: this.$store.state.chat.activeContact.chatID,
          userID: this.$store.state.chat.userProfileId,
        },
      }).then((res) => {
        const response = JSON.parse(res.request.response);
        const { data } = response;
        this.$store.dispatch("chat/updateMessageOnMessageList", {
          _id: message._id ? message._id : -1,
          messageID: message.id,
          payload: {
            data: { ...message.data, fileStatus: 1, fileURL: data.url },
          },
        });
        return data.url;
      });
    },
    filterContacts() {
      if (this.timerSearchQuery) {
        clearTimeout(this.timerSearchQuery);
        this.timerSearchQuery = null;
      }
      this.timerSearchQuery = setTimeout(() => {
        this.$store.dispatch("chat/setSearchQuery", this.searchQueryLocal);
      }, 800);
    },
    findLastChannelInMessagesList(messageList, activeContact) {
      const finder = (index) => {
        if (messageList[index - 1] === undefined) return activeContact.channel;
        else if (messageList[index - 1].author !== "system")
          return messageList[index - 1].channel;
        else return finder(index - 1);
      };

      return finder(messageList.length);
    },
    filterQuickMessages() {
      if (this.searchQueryQuickMessages !== "") {
        this.inboxQuickMessagesFiltered = this.inboxQuickMessages.filter(
          (i) =>
            i.message
              .toLowerCase()
              .includes(this.searchQueryQuickMessages.toLowerCase()) ||
            i.title
              .toLowerCase()
              .includes(this.searchQueryQuickMessages.toLowerCase())
        );
      } else {
        this.inboxQuickMessagesFiltered = this.inboxQuickMessages;
      }

      if (this.inboxQuickMessagesFiltered.length > 2)
        this.inboxQuickMessagesFiltered = this.inboxQuickMessagesFiltered.slice(
          0,
          3
        );
    },
    formatIntentionName(intention) {
      if (intention.length === 0) intention = this.$t("Others");
      return `${intention[0].toUpperCase()}${intention.slice(1).toLowerCase()}`;
    },
    finishSync() {
      this.attendanceSync = false;
      this.attendanceSyncStarted = null;
      this.showLoadMoreConversations = false;
    },
    async getChats() {
      await this.$http
        .get("/p/chat/list", {
          params: {
            acc: this.$store.state.acc.current_acc.id,
          },
        })
        .then((response) => {
          if (response.data.data.length === 1)
            this.addConversationChat = {
              label: response.data.data[0].name,
              value: response.data.data[0]._id,
              data: response.data.data[0],
            };
          for (let i = 0; i < response.data.data.length; i++) {
            this.chatOptions.push({
              label: response.data.data[i].name,
              value: response.data.data[i]._id,
              data: response.data.data[i],
            });
          }
          for (let i = 0; i < response.data.data.length; i++) {
            try {
              axios({
                method: "get",
                url: `/p/chat/inbox/provider/whatsapp-api/templates/${response.data.data[i]._id}`,
                params: { acc: this.$store.state.acc.current_acc.id },
              }).catch(() => {});
            } catch (e) {
              //ignore
            }
          }
        })
        .catch((error) => {
          if (typeof error.request !== "undefined")
            if (typeof error.request.response !== "undefined")
              error.message = JSON.parse(error.request.response).message;
            else error.message = this.$t("UnexpectedErrorLoadX", ["chatbots"]);
          this.$vs.notify({
            title: this.$t("UnexpectedError"),
            text: error.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "warning",
            position: "top-right",
            time: 4000,
          });
        });
    },
    getUserStatus(isActiveUser) {
      return isActiveUser
        ? this.$store.state.user.status
        : this.contacts[this.activeChatUser].status;
    },
    async createQuickMessage() {
      const fd = new FormData();

      if (this.quickMessageMedia) {
        fd.append("content", this.quickMessageMedia);
      }

      fd.append("title", this.createQuickMessages.title);
      fd.append("message", this.createQuickMessages.message);
      if (this.createQuickMessages.intentions.length > 0) {
        this.createQuickMessages.intentions.forEach((item) => {
          if (item.value) fd.append("intentions[]", item.value);
        });
      }
      if (this.createQuickMessages.entities.length > 0) {
        this.createQuickMessages.entities.forEach((item) => {
          if (item.value) fd.append("entities[]", item.value);
        });
      }

      fd.append("acc", this.$store.state.acc.current_acc.id);
      fd.append("createdBy", this.$store.state.user._id);

      try {
        await axios.post("/p/chat/inbox/quickMessages", fd);
        this.$vs.notify({
          time: 2500,
          title: this.$t("Ok"),
          text: this.$t("QuickMessageCreated"),
          iconPack: "feather",
          icon: "icon-check",
          color: "success",
          position: "top-right",
        });

        await this.getQuickMessages();
        this.createQuickMessagesPopup = false;
        this.createQuickMessages = {
          title: "",
          message: "",
          intentions: [],
          entities: [],
        };
        this.quickMessageMedia = null;
        this.quickMessageMediaMimetype = "";
      } catch (e) {
        this.$vs.notify({
          time: 2500,
          title: this.$t("Error"),
          text: e,
          iconPack: "feather",
          icon: "icon-check",
          color: "danger",
          position: "top-right",
        });
      }
    },
    async getQuickMessagesWppApi() {
      this.inboxQuickMessagesWppAPIApproved = [];
      const responseA = await axios.get("/p/chat/inbox/quickMessagesWppApi", {
        params: {
          acc: this.$store.state.acc.current_acc.id,
          chat: this.activeContact.chatID,
          intentions: this.activeContact.intentions,
          entities: this.activeContact.entities,
          channel: this.channel,
        },
      });

      const quickMessagesApiResult = responseA.data.data;

      quickMessagesApiResult.forEach((el) => {
        const approvedTemplates = [];
        if (el.whatsappApiTemplates)
          el.whatsappApiTemplates.forEach((el2) => {
            if (el2.templateStatus.toLowerCase() === "approved") {
              approvedTemplates.push(el2);
            }
          });
        if (el.components) {
          if (el.status.toLowerCase() === "approved") {
            approvedTemplates.push(el);
          }
        }
        const currentElement = el;
        currentElement.whatsappApiTemplates = approvedTemplates;
        this.inboxQuickMessagesWppAPIApproved.push(currentElement);
      });

      this.inboxQuickMessagesWppAPIApprovedOnChatFiltered =
        this.inboxQuickMessagesWppAPIApprovedOnChat;
    },
    async getQuickMessages() {
      const responseB = await axios.get("/p/chat/inbox/quickMessages", {
        params: {
          acc: this.$store.state.acc.current_acc.id,
          chat: this.activeContact.chatID,
          intentions: this.activeContact.intentions,
          entities: this.activeContact.entities,
        },
      });

      const quickMessagesResult = responseB.data.data;
      this.inboxQuickMessagesFiltered = quickMessagesResult.slice(0, 3);
      await this.$store.dispatch(
        "chat/setInboxQuickMessages",
        quickMessagesResult
      );
    },
    async getConversationMessages(id) {
      try {
        let query = "";
        if (this.activeContact.inAttendance)
          query += `operator=${this.currentOperator._id}&`;
        if (this.activeContact.inAttendance)
          query += `inAttendance=${this.activeContact.inAttendance}&`;
        if (this.activeContact.sessionID)
          query += `sessionid=${this.activeContact.sessionID}&`;
        query += "end=1";
        if (!id) return;
        const response = await axios({
          method: "get",
          url: `/p/chat/conversation/messages/${this.currentAccId}/${id}?${query}`,
        });

        this.$vs.loading.close("#chatlog > .con-vs-loading");
        const data = response.data.data;
        if (typeof data === "string" && data === "conversation was seized") {
          return -1;
        }

        return data;
      } catch (err) {
        this.$vs.loading.close();
      }
    },
    handleFilesBeforeUpload(e, receivedFiles = false) {
      const channelMediaData = this.channelMediaData(this.channel);
      if (!channelMediaData) return;
      if (!channelMediaData.mediaAvailable) {
        this.$vs.notify({
          time: 5000,
          title: this.$t("Error"),
          text: this.$t("ChannelDontAcceptMedia"),
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
          position: "top-right",
        });
        return;
      }
      let files = e && e.target ? e.target.files : null;
      if (receivedFiles) files = receivedFiles;
      if (!files) {
        this.$vs.notify({
          time: 5000,
          title: this.$t("Error"),
          text: this.$t("ErrorOnSendFileMessage"),
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
          position: "top-right",
        });
        return null;
      }

      files.forEach((item, i) => {
        const itemType = item.type.split("/")
          ? item.type.split("/")[0]
          : "file";

        const itemTypeSizeLimit = this.channelMediaDataSizeLimitMB(
          this.channel,
          itemType
        );

        if (i > 29) {
          e.preventDefault();
          this.$vs.notify({
            time: 5000,
            title: this.$t("Error"),
            text: "Limite de 30 arquivos atingido",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
            position: "top-right",
          });
        } else if (item.size > itemTypeSizeLimit * 1000000) {
          e.preventDefault();
          this.$vs.notify({
            time: 5000,
            title: this.$t("Error"),
            text: this.$t("FileIsTooLarge", [itemTypeSizeLimit]),
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
            position: "top-right",
          });
        } else {
          if (item.type.includes("image")) {
            item = this.resizePicture(item);
          } else {
            this.filesToUploadPreview.push({
              type: item.type,
              content: item.type.includes("image")
                ? URL.createObjectURL(item)
                : null,
              name: item.name,
            });
            this.filesToUpload.push({ rawItem: item });
          }
        }
      });
      if (e && e.target) e.target.value = null;
    },
    async initilizeInbox() {
      this.$http
        .get(`/p/chat/inbox/${this.$store.state.acc.current_acc.id}`, {
          params: { acc: this.$store.state.acc.current_acc.id },
        })
        .then(async (response) => {
          const thisIns = this;
          const data = response.data.data;
          this.inboxSignOperator = data.inbox.signOperator;
          this.blockCreateConversationOperator =
            !!data.inbox.blockCreateConversationOperator;
          this.blockWaitingServiceTab = !!data.inbox.blockWaitingServiceTab;
          this.inboxSettingsData = data.inbox;
          this.inboxWarningFlags = data.inbox.inboxWarningFlags;
          this.closingConversationReasons =
            this.inboxSettingsData.closingConversationReasons;
          this.salesSuccessReasons = this.inboxSettingsData.salesSuccessReasons;
          this.salesFailureReasons = this.inboxSettingsData.salesFailureReasons;

          if (data.inbox.ia) this.ia = data.inbox.ia;
          const intentions = [];
          const keyEntities = [];
          data.inbox.keyEntities.forEach((value) => {
            keyEntities.push({ label: value, value });
          });
          keyEntities.unshift({
            label: this.$t("All"),
            value: "__all__",
          });
          data.inbox.intentions.forEach((value) => {
            intentions.push({ label: value, value });
          });
          intentions.unshift({
            label: this.$t("All"),
            value: "__all__",
          });
          intentions.push({
            label: this.$t("Others"),
            value: "__other__",
          });

          this.createQuickMessagesOptions = {
            intentions: intentions,
            entities: keyEntities,
          };

          const inboxIntentions = [...data.inbox.intentions, "outros"];
          const operatorIntentions = data.operator.intentions;

          const currentOperator = await this.$db
            .collection(`inbox-operator-${this.currentAccId}`)
            .doc(data.operator._id)
            .get();
          if (currentOperator) {
            if (
              new Date(data.operator.updatedAt).getTime() >
              new Date(currentOperator.updatedAt).getTime()
            ) {
              await this.$db
                .collection(`inbox-closed-conversations-${this.currentAccId}`)
                .delete();
              await this.$db
                .collection(`inbox-conversations-${this.currentAccId}`)
                .delete();
              await this.$db
                .collection(`inbox-conversations-config-${this.currentAccId}`)
                .delete();
            }
          }
          await this.$db
            .collection(`inbox-operator-${this.currentAccId}`)
            .add(data.operator, data.operator._id);

          this.channelsConfig = data.channelsConfig;

          this.inboxIntentions = data.inbox.intentions;
          if (data.inboxChatsConfig) {
            data.inboxChatsConfig.forEach((el) => {
              if (el.wppApi360DialogCloudApi) {
                this.cloudChats.push(el.chat);
              }
              thisIns.inboxChannelsAvailable.push({
                chat: el.chat,
                webChatStatus: el.webChatStatus === 1,
                webChatAIStatus: el.webChatStatus === 1,
                wppApiStatus: el.wppApiStatus === 1,
                wppApi360DialogStatus: el.wppApi360DialogStatus === 1,
                smsStatus: el.smsStatus === 1,
                wppStatus: el.wppStatus === 1,
                facebookStatus: el.facebookStatus === 1,
                instagramStatus: el.instagramStatus === 1,
              });

              thisIns.proactiveChannels.push({
                chat: el.chat,
                wppApi: el.wppApiStatus === 1,
                wppApi360: el.wppApi360DialogStatus === 1,
                sms: el.smsStatus === 1,
                wpp: el.wppStatus === 1,
              });
            });
          }
          this.inboxOperatorsList = data.operators;
          if (
            data.operator &&
            data.operator.intentions &&
            data.operator.intentions.indexOf("__all__") === -1
          ) {
            this.$store.dispatch(
              "chat/setIntentionsArrayStored",
              operatorIntentions
            );
          } else {
            this.oprAllIntentions = true;
            this.$store.dispatch(
              "chat/setIntentionsArrayStored",
              inboxIntentions
            );
          }
          this.$store.dispatch("chat/setAllEntities", data.inbox.keyEntities);
          if (
            data.operator.entities &&
            data.operator.entities.indexOf("__all__") === -1 &&
            data.operator.entities.length > 0
          ) {
            let entities = data.operator.entities;
            const index = entities.indexOf("__na__");
            if (index > -1) {
              entities.splice(index, 1);
            }
            this.$store.dispatch("chat/setEntitiesArray", entities);
          } else {
            this.$store.dispatch(
              "chat/setEntitiesArray",
              data.inbox.keyEntities
            );
          }
          //SET_ENTITIES_ARRAY
          await this.$store.dispatch(
            "chat/setInboxOperatorsArrayStored",
            data.operators
          );
          this.$store.dispatch(
            "chat/setInboxOperatorsArray",
            this.inboxOperatorsArrayStored.map((operator) => operator.value)
          );
          this.$store.dispatch(
            "chat/setIntentionsArray",
            this.intentionsArrayStored
          );
          this.$store.dispatch("chat/setCurrentOperator", data.operator);
          let allIntentions;
          allIntentions = [
            ...this.inboxOperatorsArrayStored.flatMap(
              (operator) => operator.intentions
            ),
            ...data.inbox.intentions,
          ];
          allIntentions = allIntentions.reduce((acc, intention) => {
            if (
              intention &&
              !acc.find(
                (accIntention) =>
                  accIntention.toLowerCase() === intention.toLowerCase()
              )
            )
              acc.push(intention);
            return acc;
          }, []);
          this.$store.dispatch("chat/setAllIntentions", allIntentions);
          await this.initSocket();
          this.$vs.loading.close();
        })
        .catch(() => {
          this.$vs.dialog({
            color: "warning",
            title: "Oops",
            text: this.$t("YouDontHaveAccess"),
            accept: () => {
              this.$router.push({ name: "dashboard" });
            },
            cancel: () => {
              this.$router.push({ name: "dashboard" });
            },
            acceptText: "Ok",
          });
          this.$vs.loading.close();
        });
    },
    handleWindowResize(event) {
      this.windowWidth = event.currentTarget.innerWidth;
      this.setSidebarWidth();
    },
    initAddConversationPopup({ name, phone }) {
      let foundedContact;
      if (phone)
        foundedContact = this.conversationSearchContacts.find(
          (e) => e.phone === phone
        );
      if (foundedContact) this.addConversationContact = foundedContact;
      else {
        this.addConversationName = name;
        this.addConversationPhone = phone;
      }
      this.addConversationPopup = true;
    },
    async initClosedTab() {
      if (!this.loadingClosed) {
        this.loadingClosed = true;

        await this.closeAttendance(false);

        const filters = { search: this.searchQuery };

        const closedConv =
          this.conversations.getAll().length > 0
            ? this.conversations.getAll().filter((el) => el.closed)
            : [];

        const lastID =
          closedConv.length > 0
            ? closedConv[closedConv.length - 1].contactSessionID
            : null;

        //if the conversation come from feedback page
        if (this.$route.query.conversation) {
          filters._id = this.$route.query.conversation;
          delete this.$route.query.conversation;
        }

        socket.emit(
          "get closed conversations",
          lastID,
          filters,
          async (conversations) => {
            this.loadingClosed = false;
            for (let i = 0; i < conversations.length; i += 1) {
              this.conversations.createOrUpdate(conversations[i]);
            }

            if (filters._id && conversations.length > 0) {
              const treatedConversation = this.treatConversation(
                conversations[0]
              );
              this.updateActiveChatUser(
                treatedConversation,
                treatedConversation.id
              );
            }
            this.loadingConversationsSpin = false;
            this.showLoadMoreConversations = true;

            this.$vs.loading.close();
          }
        );
      }
    },
    initSocket() {
      this.$vs.loading();
      const accID = this.currentAccId;
      const userID = this.activeUserInfo._id;
      /* socket */
      const token = this.$store.state.auth.accessToken;

      if (this.currentOperator.intentions) {
        socket.reconData = JSON.stringify({
          intentions: this.intentionsArrayStored,
        });
      }

      socket.auth = {
        accID,
        userID,
        token,
        platform: "web",
        reconData: socket.reconData,
      };

      socket.accID = accID;
      socket.userID = userID;

      const self = this;
      const thisIns = this;

      let inConflict = false;

      socket.connect();

      socket.onAny(() => {
        this.socketLastPing = Date.now();
      });

      socket.on("connection_conflict", () => {
        this.$vs.loading.close();
        this.$store.dispatch("chat/setSearchQuery", "");
        this.$store.dispatch("chat/reset");
        socket.removeAllListeners();
        socket.disconnect();
        socket.destroy();

        if (!inConflict)
          this.$vs.dialog({
            type: "confirm",
            color: "warning",
            title: this.$t("Warning"),
            text: this.$t("WebsocketConflict"),
            accept: () => {
              this.$vs.loading();
              location.reload();
            },
            cancel: () => {
              this.$router.push("/new-dashboard");
              inConflict = !inConflict;
            },
            close: () => {
              this.$router.push("/new-dashboard");
              inConflict = !inConflict;
            },
            acceptText: this.$t("UseHere"),
            cancelText: this.$t("Close"),
          });
      });
      /* socket */
      socket.on("connect", () => {
        this.$vs.loading.close();
        socket.emit("loadAttendance", 2);
        this.socketLastPing = Date.now();
      });

      socket.on("ready", async () => {
        this.$vs.loading.close();
        this.socketLastPing = Date.now();
        if (this.activeTab === 2) await this.initClosedTab(true);
        else {
          await this.updateConversations();
          this.applyFilters();
        }
        this.isReady = true;
        if (this.activeContact && this.activeContact.id) {
          const id = this.activeContact.id;
          const currentItem = this.conversations.findOne(id);
          if (currentItem && currentItem.inAttendance) {
            if (this.activeTab !== 1)
              await this.$store.dispatch("chat/setActiveTab", 1);
            this.updateActiveChatUser(currentItem, id);
          }
        }
      });

      socket.on("no inbox", () => {
        this.$vs.loading.close();
        this.$vs.notify({
          title: this.$t("Error"),
          text: this.$t("YouDontHaveAccess"),
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "warning",
          position: "top-right",
          time: 4000,
        });

        this.$router.push("/new-dashboard");
      });

      socket.on("error", (data) => {
        self.$vs.loading.close();

        this.$vs.notify({
          title: this.$t("Error"),
          text: data,
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
          position: "top-right",
          time: 4000,
        });
      });

      socket.on("new conversation", async (conversation) => {
        if (
          (this.currentOperator.entities.length > 0 &&
            conversation.entities.length === 0) ||
          (this.currentOperator.entities.length > 0 &&
            conversation.entities &&
            conversation.entities.length > 0 &&
            !conversation.entities.some((entity) =>
              this.currentOperator.entities.includes(entity)
            ))
        )
          return;
        await this.createNewConversation(conversation);
      });

      socket.on("contact phase changed", (phase) => {
        this.conversations.update(this.activeContact.id, { phase });
      });

      socket.on("conversation seized", async ({ contactSessionID, userID }) => {
        if (userID !== this.currentOperator._id) {
          this.seizeConversation(contactSessionID);
        }
      });

      socket.on(
        "conversation by another operator",
        async ({ conversationID }) => {
          const id = conversationID;
          if (this.conversations.findOne(id)) {
            if (id === this.activeChatUser) this.resetActiveChatUser();
            this.totalConversations = this.totalConversations - 1;
            this.conversations.delete(id);
            this.$vs.notify({
              title: this.$t("Error"),
              text: this.$t("ErrorAttendanceAnotherOperator"),
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger",
              position: "top-right",
              time: 4000,
            });
          }
        }
      );

      socket.on("close conversation", () => {
        this.resetActiveChatUser();
      });

      socket.on(
        "update conversation",
        async (
          conversation,
          {
            operator,
            intentions,
            entities,
            name,
            email,
            phone,
            field,
            value,
            postponedTo,
          }
        ) => {
          await this.updateConversation(conversation, {
            operator,
            intentions,
            entities,
            name,
            email,
            phone,
            field,
            value,
            postponedTo,
          });
        }
      );

      /* socket conversation */
      socket.on("messages history", (data) => {
        this.messageList = data.messages.map((messageItem) => {
          if (
            messageItem.type === "file" &&
            messageItem.data.file.includes("s3.amazonaws")
          ) {
            messageItem.data.fileStatus = 0;
            messageItem.data.file = "";
          }
          return messageItem;
        });
        if (
          typeof data.participants === "object" &&
          data.participants.length > 0
        )
          this.$store.dispatch("chat/setParticipants", data.participants);
      });

      socket.on("private message", async (data) => {
        if (data.message.type === "file") {
          data.message.data.fileStatus = 0;
        }
        if (data.participants)
          await this.$store.dispatch("chat/setParticipants", data.participants);
        await this.$store.dispatch("chat/addMessageList", data.message);
        this.conversations.update(this.activeContact.id, {
          updated: new Date().toISOString(),
          timestamp: Date.now(),
        });
      });

      socket.on("update pending response", (inboxConversation) => {
        const id = inboxConversation._id;
        const currentitem = this.conversations.findOne(id);
        if (
          currentitem &&
          !currentitem.pendingResponse &&
          inboxConversation.pendingResponse &&
          currentitem.inAttendance
        ) {
          this.playAudio(newMessageNotification);
        }
        if (currentitem) {
          this.conversations.update(id, {
            pendingResponse: inboxConversation.pendingResponse,
            updated: new Date().toISOString(),
            timestamp: Date.now(),
          });
        }
      });

      socket.on(
        "conversation update",
        (inboxConversationID, { prop, value }) => {
          this.conversations.update(inboxConversationID, { [prop]: value });
        }
      );

      socket.on("authentication ongoing", () => {
        this.$vs.notify({
          title: "WhatsApp Reconectando",
          text: "Aguarde que o WhatsApp está reconectando.",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "warning",
          position: "top-right",
          time: 10000,
        });
      });

      socket.on("conversation was seized", async (data) => {
        if (
          data &&
          data.conversationID &&
          data.conversationID === this.activeContact.id
        )
          this.resetActiveChatUser();

        if (data.conversationID) {
          this.resetActiveChatUser();
          setTimeout(async () => {
            await thisIns.$store.dispatch("chat/setActiveTab", 1);
            const id = data.conversationID;

            const currentItem = this.conversations.findOne(id);
            if (currentItem) {
              thisIns.updateActiveChatUser(currentItem, id);
              thisIns.$vs.notify({
                title: thisIns.$t("Success"),
                text: thisIns.$t("FoundedAlreadyInAttendance"),
                iconPack: "feather",
                icon: "icon-check",
                color: "success",
                position: "top-right",
                time: 10000,
              });

              this.conversations.update(id, {
                inAttendance: true,
                operator: data.operator,
                updated: new Date().toISOString(),
                timestamp: Date.now(),
              });
            } else {
              this.showToastSeizedConversation();
            }
          }, 800);
        } else {
          this.showToastSeizedConversation();
        }
      });

      socket.on("wpp disconnected", () => {
        //this.showWppDisconnected();
      });

      socket.on("disconnect", () => {});

      socket.on("send message error number not found", () => {
        this.$vs.notify({
          title: this.$t("Error"),
          text: this.$t("NumberDoesNotExist"),
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
          position: "top-right",
          time: 4000,
        });
      });

      socket.on("wpp reconnected", () => {
        this.$vs.dialog({
          color: "warning",
          title: "Conectando",
          text: "Reestabelencendo a conexão com o WhatsApp... As últimas mensagens podem não ter sido enviadas.",
          accept: () => {},
          acceptText: "Ok",
        });
      });

      socket.on("whatsapp target closed", () => {
        if (!this.wppTargetNotification) {
          this.wppTargetNotification = true;
          this.$vs.notify({
            title: "Conexão WhatsApp Instável",
            text: "Verifique se o celular está conectado com a internet.",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "warning",
            position: "top-right",
            time: 10000,
          });
        }
        const thisIns = this;
        setTimeout(function () {
          thisIns.wppTargetNotification = false;
        }, 10000);
      });

      socket.on("whatsapp target back", () => {
        this.wppTargetNotification = false;
        this.$vs.notify({
          title: "Conexão WhatsApp Ok",
          text: "Seu WhatsApp está online novamente.",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "success",
          position: "top-right",
          time: 20000,
        });
      });

      socket.on("update message", (messageID, message, sessionID) => {
        if (message.type === "file") message.data.fileStatus = 0;
        if (this.activeContact.sessionID === sessionID) {
          if (message && Object.keys(message).length > 0) {
            let insertIfNull = true;
            if (message.reactions && Object.keys(message).length === 1)
              insertIfNull = false;
            this.$store.dispatch("chat/updateMessageOnMessageList", {
              _id: message._id || -1,
              messageID,
              payload: { ...message },
              insertIfNull,
            });
          }
        }
      });

      socket.on(
        "update message id",
        (oldMessageID, newMessageID, sessionID) => {
          if (this.activeContact.sessionID === sessionID) {
            this.$store.dispatch("chat/updateMessageIDOnMessageList", {
              oldMessageID,
              newMessageID,
            });
          }
        }
      );
    },
    insertEmoji(emoji) {
      this.typedMessage += `${emoji}`;
      this.emojiKeyForceRerender += 1;
    },
    async loadMoreConversations() {
      this.loadingConversationsSpin = true;
      if (this.activeTab === 2) await this.loadMoreClosedConversations();
      else {
        await this.updateConversations(2);
        this.applyFilters();
      }
    },
    async loadMoreClosedConversations(replace, forceSearch = false) {
      if (!this.loadingClosed) {
        this.loadingClosed = true;
        if (replace) {
          this.showLoadMoreConversations = true;
          this.contactsPagination = 0;
        }
        this.loadingConversationsSpin = true;

        const filters = { search: this.searchQuery };

        if (this.intentionsArray.length !== this.intentionsArrayStored.length)
          filters.intentions = this.intentionsArray;
        if (
          this.inboxOperatorsArray.length !==
          this.inboxOperatorsArrayStored.length
        )
          filters.operators = this.inboxOperatorsArray;

        let closedConv =
          this.conversations.getAll().length > 0
            ? this.conversations.getAll().filter((el) => el.closed)
            : [];

        closedConv = closedConv.sort(function (a, b) {
          return a.id - b.id;
        });

        const lastID =
          closedConv.length > 0
            ? closedConv[closedConv.length - 1].contactSessionID
            : null;

        socket.emit(
          "get closed conversations",
          lastID,
          filters,
          async (conversations) => {
            this.loadingClosed = false;
            if (conversations.length === 0) {
              this.loadingConversationsSpin = false;
              this.showLoadMoreConversations = false;
            } else {
              for (let i = 0; i < conversations.length; i += 1) {
                this.conversations.createOrUpdate(conversations[i]);
              }
              if (forceSearch) this.applyFilters(true);
              this.loadingConversationsSpin = false;
              this.showLoadMoreConversations = true;

              this.$vs.loading.close();
            }
          }
        );
      } else this.$vs.loading.close();
    },
    mountIntentions() {
      this.$store.dispatch(
        "chat/setIntentionsArray",
        this.intentionsArrayStored
      );
    },
    onDrop(event) {
      event.preventDefault();
      event.stopPropagation();

      this.showDropzone = false;

      const files = event.dataTransfer.files;
      if (!this.teamChatOpen) {
        if (files) this.handleFilesBeforeUpload(null, files);
      }
    },
    playAudio(file) {
      var audio = new Audio(file);
      audio.play().catch(() => {});
    },
    pasteOnTextBox(event, mediaAvailable) {
      let paste = event.clipboardData || window.clipboardData;

      let file = [];
      for (var i = 0; i < paste.items.length; i++) {
        var item = paste.items[i];
        if (mediaAvailable && item.kind === "file") {
          file = [item.getAsFile()];
        }
      }
      if (file.length > 0) this.handleFilesBeforeUpload(null, file);
    },
    pushRouteQueryParams(params, removeAll = false) {
      if (!removeAll) params = Object.assign(this.$route.query, params);
      history.pushState(
        {},
        null,
        this.$route.path +
          "?" +
          Object.keys(params)
            .map((key) => {
              return (
                encodeURIComponent(key) + "=" + encodeURIComponent(params[key])
              );
            })
            .join("&")
      );
    },
    resetActiveChatUser() {
      this.messagesListPage = 1;
      this.$store.dispatch("chat/setActiveChatUser", null);
      this.$store.dispatch("chat/setActiveProfileSidebar", false);
      this.$store.dispatch("chat/setUserProfileId", -1);
      this.$store.dispatch("chat/setActiveContact", {});
      this.toogleProfileSidebar(false);
      this.$vs.loading.close();
      this.$vs.loading.close("#chatlog > .con-vs-loading");
      this.pushRouteQueryParams(
        {
          acc: this.$store.state.acc.current_acc.id,
          activeTab: this.activeTab,
        },
        true
      );
    },
    removeImageToUpload(index) {
      if (this.filesToUploadPreview.length === 1) this.cleanMessageInput();
      else if (this.fileToUploadPreviewIndex > 0)
        this.fileToUploadPreviewIndex = this.fileToUploadPreviewIndex - 1;

      this.filesToUploadPreview.splice(index, 1);
      this.filesToUpload.splice(index, 1);
      if (this.filesToUpload.length === 0) this.cleanMessageInput();
    },
    removeConversationContact() {
      this.addConversationContact = "";
    },
    async resendMsg(messageItem) {
      if (!this.socketConnOnline) {
        this.$vs.notify({
          title: this.$t("YoureOffline"),
          text: this.$t("NetworkIssues"),
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
          position: "top-right",
          time: 4000,
        });
        return;
      }

      const ack = 0;
      socket.emit(
        "private message 2",
        {
          message: {
            ...messageItem,
            ack,
            timestamp: Date.now(),
          },
          sessionID: this.activeContact.sessionID,
          conversationID: this.activeContact.contactSessionID,
          conversationMessageID: messageItem._id,
          chatID: this.activeContact.chatID,
          operator: this.currentOperator._id,
          resend: true,
          version: 2,
        },
        async (response) => {
          if (response.status === "ok") {
            await this.removeUnsendedMessage(
              this.activeContact.id,
              response.uid
            );
            await this.setMessageSent(response.message);
            this.$store.dispatch("chat/sortMessageList");
          } else if (response.reason === "seized") {
            this.showToastSeizedConversation();
          }
          return true;
        }
      );
    },
    async resizePicture(imageFile) {
      const thisIns = this;

      const reader = new FileReader();
      reader.onload = function (e) {
        var img = document.createElement("img");
        img.onload = function () {
          // Dynamically create a canvas element
          var MAX_WIDTH = 1600;
          var MAX_HEIGHT = 1200;

          var width = img.width;
          var height = img.height;

          // Change the resizing logic
          if (width > height) {
            if (width > MAX_WIDTH) {
              height = height * (MAX_WIDTH / width);
              width = MAX_WIDTH;
            }
          } else {
            if (height > MAX_HEIGHT) {
              width = width * (MAX_HEIGHT / height);
              height = MAX_HEIGHT;
            }
          }

          const canvas = document.createElement("canvas");
          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0, width, height);

          const content = canvas.toDataURL(imageFile.type);
          thisIns.filesToUploadPreview.push({
            type: imageFile.type,
            content,
            name: imageFile.name,
          });
          const resizedImage = thisIns.dataURLToBlob(content);
          thisIns.filesToUpload.push({
            rawItem: imageFile,
            image: resizedImage,
          });
        };
        img.src = e.target.result;
      };
      reader.readAsDataURL(imageFile);
    },
    async removeUnsendedMessage(id, uid) {
      const unsendedMessage = await this.$db
        .collection(this.localDBTable)
        .doc(id)
        .get();
      if (unsendedMessage) {
        const messages = unsendedMessage.messages;
        const index = messages.findIndex((el) => el.uid === uid);
        if (index > -1) {
          messages.splice(index, 1);
          if (messages.length === 0)
            await this.$db.collection(this.localDBTable).doc(id).delete();
          else
            await this.$db.collection(this.localDBTable).doc(id).update({
              messages,
            });
        }
      }
    },
    async setMessageSent(data) {
      await this.$store.dispatch("chat/updateMessageOnMessageList", {
        _id: data._id || -1,
        messageID: data.id,
        uid: data.uid,
        payload: data,
        insertIfNull: false,
      });
    },
    seizeConversation(id) {
      if (this.conversations.findOne(id)) {
        if (id === this.activeChatUser) this.resetActiveChatUser();
        this.totalConversations = this.totalConversations - 1;
        this.conversations.delete(id);
      }
    },
    showToastSeizedConversation() {
      this.$vs.notify({
        title: this.$t("Error"),
        text: this.$t("ErrorAttendanceAnotherOperatorOrNotFounded"),
        iconPack: "feather",
        icon: "icon-alert-circle",
        color: "danger",
        position: "top-right",
        time: 10000,
      });
    },
    showSidebar() {
      this.$store.commit("TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE", true);
    },
    startRecording() {
      const workerOptions = {
        OggOpusEncoderWasmPath: `${window.location.protocol}//${window.location.host}/codec/OggOpusEncoder.wasm`,
        WebMOpusEncoderWasmPath: `${window.location.protocol}//${window.location.host}/codec/WebMOpusEncoder.wasm`,
      };
      window.MediaRecorder = OpusMediaRecorder;
      navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
        let options = { mimeType: "audio/ogg;codecs=opus" };
        // Start recording
        this.$store.dispatch(
          "chat/setRecorder",
          new MediaRecorder(stream, options, workerOptions)
        );
        let startTime = Date.now();
        this.mediaAudioInterval = setInterval(() => {
          let elapsedTime = Date.now() - startTime;

          let minutes = Math.floor(elapsedTime / 60000);
          let seconds = Math.floor((elapsedTime % 60000) / 1000);

          this.mediaAudioRecordingMin = minutes < 10 ? "0" + minutes : minutes;
          this.mediaAudioRecordingSec = seconds < 10 ? "0" + seconds : seconds;
        }, 1000);
        // Set record to <audio> when recording will be finished
        this.$store.state.chat.recorder.addEventListener(
          "dataavailable",
          (e) => {
            this.$store.dispatch(
              "chat/setAudioSource",
              URL.createObjectURL(e.data)
            );
            this.$store.dispatch("chat/setBlob", e.data);
          }
        );
        this.$store.state.chat.recorder.start();
      });
    },
    stopRecording() {
      this.$store.state.chat.recorder.stop();
      this.$store.state.chat.recorder.stream
        .getTracks()
        .forEach((i) => i.stop());
      this.$store.dispatch("chat/setRecorder", null);
      clearInterval(this.mediaAudioInterval);
    },
    async sendMsg(e, type, msg) {
      if (!this.socketConnOnline) {
        this.$vs.notify({
          title: this.$t("YoureOffline"),
          text: this.$t("NetworkIssues"),
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
          position: "top-right",
          time: 4000,
        });
        return;
      }

      const conversationID = this.activeContact.contactSessionID;
      const id = this.activeContact.id;

      this.conversations.update(id, {
        updated: new Date().toISOString(),
        timestamp: Date.now(),
      });

      const operator = `*${this.activeUserName}*:\n`;

      const sessionID = this.activeContact.sessionID;
      const chatID = this.activeContact.chatID;
      const typedMessage = msg
        ? msg
        : this.inboxSignOperator
        ? operator + this.typedMessage
        : this.typedMessage;
      const channel = this.channel;
      const blob = this.blob;
      const audioSource = this.audioSource;
      const files = this.filesToUpload;

      if (!e || !e.shiftKey) {
        if (e) e.preventDefault();
        if (!typedMessage && !audioSource && files.length === 0) return;
        const store = this.$store;
        let whatsapp = false;
        let facebook = false;
        let instagram = false;
        let whatsappApi = false;
        let whatsappApi360Dialog = false;
        let sms = false;
        if (channel === "WhatsApp") whatsapp = true;
        if (channel === "Facebook") facebook = true;
        if (channel === "Instagram") instagram = true;
        if (channel === "WhatsApp API") whatsappApi = true;
        if (channel === "WhatsApp 360") whatsappApi360Dialog = true;
        if (channel === "SMS") sms = true;
        const addMessageListAndEmitPrivateMessage = async (msg) => {
          let currentMessage = msg;

          const unsendedMessage = await this.$db
            .collection(this.localDBTable)
            .doc(id)
            .get();

          if (currentMessage.type !== "file")
            await this.$db.collection(this.localDBTable).add(
              {
                messages: unsendedMessage
                  ? unsendedMessage.messages.concat(currentMessage)
                  : [currentMessage],
              },
              id
            );

          if (this.replyMessage) {
            currentMessage.context = {
              id: this.replyMessage.id,
            };
          }

          store.dispatch("chat/addMessageList", currentMessage);

          store.dispatch("chat/setChatReplyMessage", null);

          this.playAudio(sendMessageNotification);

          if (currentMessage.type === "file" && !currentMessage.data.fileUrl) {
            let fd = new FormData();
            if (currentMessage.data.file.image)
              fd.append("content", currentMessage.data.file.image);
            else
              fd.append(
                "content",
                currentMessage.data.file.rawItem || currentMessage.data.file
              );
            fd.append("chat", chatID);

            let filename;
            if (currentMessage.data.filename)
              filename = currentMessage.data.filename;
            else if (currentMessage.data.file.rawItem)
              filename = currentMessage.data.file.rawItem.name;
            if (filename) fd.append("filename", filename);

            fd.append("sessionid", sessionID);

            this.$http
              .post(`/p/chat/saveMessageFile`, fd, {
                headers: {
                  Authorization: "Bearer " + this.$store.state.auth.accessToken,
                  "Content-Type": "multipart/form-data",
                },
              })
              .then((res) => {
                currentMessage.data.file = res.data.data;
                currentMessage.data.fileStatus = 0;
                this.$store.dispatch("chat/updateMessageOnMessageList", {
                  _id: -1,
                  messageID: message.id,
                  payload: {
                    data: currentMessage.data,
                  },
                });

                socket.emit(
                  "private message 2",
                  {
                    message: currentMessage,
                    sessionID,
                    conversationID,
                    chatID,
                    channel,
                    version: 2,
                    operator: this.currentOperator._id,
                  },
                  async (response) => {
                    if (response.status === "ok") {
                      await this.removeUnsendedMessage(id, currentMessage.uid);
                      await this.setMessageSent(response.message);
                    } else if (response.reason === "seized") {
                      this.showToastSeizedConversation();
                    }
                    return true;
                  }
                );
              })
              .catch(() => {
                this.$store.dispatch("chat/rmMessageList", message.id);

                this.$vs.notify({
                  title: this.$t("Error"),
                  text: this.$t("ErrorOnSendFileMessage"),
                  iconPack: "feather",
                  icon: "icon-alert-circle",
                  color: "danger",
                  position: "top-right",
                  time: 4000,
                });
              });
          } else {
            socket.emit(
              "private message 2",
              {
                message: currentMessage,
                sessionID,
                conversationID,
                chatID,
                channel,
                operator: this.currentOperator._id,
                version: 2,
              },
              async (response) => {
                if (response.status === "ok") {
                  await this.removeUnsendedMessage(id, currentMessage.uid);
                  await this.setMessageSent(response.message);
                } else if (response.reason === "seized") {
                  this.showToastSeizedConversation();
                }
                return true;
              }
            );
          }
        };

        let message = {
          id: uuidV4(),
          uid: `${this.currentAccId}_${uuidV4()}`,
          author: type ? type : store.state.user._id,
          timestamp: Date.now(),
          ack: 0,
          channel,
        };

        if (this.messageFileUrl) {
          let treatedText = typedMessage;
          if (typedMessage.endsWith("\n"))
            treatedText = typedMessage.slice(0, typedMessage.length - 1);
          message.type = "file";
          message.data = {
            text: treatedText,
            file: this.messageFileUrl,
            fileUrl: this.messageFileUrl,
            mimetype: this.messageFileMimetype,
            whatsapp,
            facebook,
            instagram,
            whatsappApi,
            whatsappApi360Dialog,
            sms,
          };
          await addMessageListAndEmitPrivateMessage(message);
          this.cleanMessageInput();
        } else if (store.state.chat.audioSource) {
          const audioFileName = audioSource.split("/");
          const filename = `${audioFileName[audioFileName.length - 1]}.oga`;
          message.type = "file";
          message.data = {
            mimetype: "audio/ogg; codecs=opus",
            file: blob,
            filename,
            fileStatus: 2,
            whatsapp,
          };
          await addMessageListAndEmitPrivateMessage(message);
          this.cleanMessageInput();
        } else if (files.length > 0) {
          files.forEach((file) => {
            message = {
              id: uuidV4(),
              author: store.state.user._id,
              timestamp: Date.now(),
              ack: 0,
              channel,
            };
            if (file.image)
              store.dispatch("chat/addMessageListLocalPics", {
                id: message.id,
                url: URL.createObjectURL(file.image),
              });
            message.type = "file";
            message.data = {
              mimetype: file.rawItem.type,
              file,
              filename: file.rawItem.name,
              fileStatus: 2,
              whatsapp,
              facebook,
              instagram,
              whatsappApi,
              whatsappApi360Dialog,
              sms,
            };
            addMessageListAndEmitPrivateMessage(message);
          });
          this.cleanMessageInput();
        } else {
          let treatedText = typedMessage;
          if (typedMessage.endsWith("\n"))
            treatedText = typedMessage.slice(0, typedMessage.length - 1);
          message.type = "text";
          message.data = {
            text: treatedText,
            whatsapp,
            facebook,
            instagram,
            whatsappApi,
            whatsappApi360Dialog,
            sms,
          };
          await addMessageListAndEmitPrivateMessage(message);
          if (!type) this.cleanMessageInput();
        }
      }
    },
    startLivechat() {
      const isActive = !this.activeContact.closed;

      this.$vs.loading({ container: "#chatlog" });
      if (this.startLivechatTimer) clearTimeout(this.startLivechatTimer);
      this.startLivechatTimer = setTimeout(async () => {
        this.messageList = [];

        const treatGetConversation = async (response) => {
          const sessionid = response.sessionid;
          const messages = response.messages || response.history.messages;
          const participants =
            response.participants || response.history.participants || undefined;
          if (sessionid === this.activeContact.sessionID) {
            if (messages !== undefined) {
              this.messageList = messages;

              if (messages.length > 0) {
                const lastMessage = messages[messages.length - 1];
                this.channel = this.treatChannel(
                  lastMessage.channel.toLowerCase() === "website"
                    ? "Website"
                    : lastMessage.channel.charAt(0).toUpperCase() +
                        lastMessage.channel.slice(1)
                );
              }
            }
            if (typeof participants === "object" && participants.length > 0)
              await this.$store.dispatch("chat/setParticipants", participants);
          }
        };

        if (isActive) {
          let request = true;
          let tryN = 0;
          do {
            const response = await this.getConversationMessages(
              this.activeContact.contactSessionID,
              tryN
            );
            if (response) {
              if (response === -1) {
                this.seizeConversation(this.activeContact.contactSessionID);
                this.showToastSeizedConversation();
              } else await treatGetConversation(response);
              request = false;
            } else tryN += 1;
            if (tryN >= 3) {
              this.resetActiveChatUser();
              request = false;
              this.$vs.notify({
                title: this.$t("UnexpectedError"),
                text: this.$t("UnexpectedErrorLoadConversationMessages"),
                iconPack: "feather",
                icon: "icon-alert-circle",
                color: "danger",
                position: "top-right",
                time: 4000,
              });
            }
          } while (request);

          socket.emit(
            "get conversation 2",
            {
              sessionID: this.activeContact.sessionID,
              conversationID: this.activeContact.contactSessionID,
              chatID: this.activeContact.chatID,
              inAttendance: this.activeContact.inAttendance,
            },
            async (response) => {
              if (response.status === "ok") {
                this.$vs.loading.close("#chatlog > .con-vs-loading");
              }
              return true;
            }
          );
        } else {
          socket.emit(
            "get closed conversation",
            {
              sessionID: this.activeContact.sessionID,
              chatID: this.activeContact.chatID,
              conversationID: this.activeContact.contactSessionID,
            },
            async (response) => {
              await treatGetConversation(response);
              this.$vs.loading.close("#chatlog > .con-vs-loading");
              return true;
            }
          );
        }
      }, 650);
    },
    setOperator(operatorID, intention, entity) {
      const thisIns = this;
      let showingLoading = true;
      this.$vs.loading();
      const channel = this.findLastChannelInMessagesList(
        this.messageList,
        this.activeContact
      );

      socket.emit(
        "set conversation operator",
        {
          contactSessionID: this.activeContact.contactSessionID,
          sessionID: this.activeContact.sessionID,
          chat: this.activeContact.chatID,
          inAttendance: true,
          operatorID: operatorID,
          intention: intention !== "outros" ? intention : "",
          entity: entity.length > 0 ? entity : null,
          timestamp: Date.now(),
          channel,
        },
        async (response) => {
          showingLoading = false;
          this.$vs.loading.close();
          if (response.status === "ok") {
            this.toogleProfileSidebar(false);
            this.resetActiveChatUser();
            this.$vs.notify({
              time: 2500,
              title: this.$t("Success"),
              text: "Encaminhado com sucesso",
              iconPack: "feather",
              icon: "icon-check",
              color: "success",
              position: "top-right",
            });
          } else {
            thisIns.$vs.notify({
              title: thisIns.$t("Error"),
              text: thisIns.$t("ErrorOnForwardConversation"),
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "warning",
              position: "top-right",
              time: 4000,
            });
          }
        }
      );

      setTimeout(function () {
        if (showingLoading) {
          thisIns.$vs.loading.close();
          thisIns.$vs.notify({
            title: thisIns.$t("Error"),
            text: thisIns.$t("ErrorOnForwardConversation"),
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "warning",
            position: "top-right",
            time: 4000,
          });
        }
      }, 6000);
    },
    showProfileSidebar(userId) {
      this.userProfileId = userId;
      this.$store.dispatch("chat/setActiveProfileSidebar", true);
    },
    shiftRouteQueryParam(name) {
      const params = this.$route.query;
      if (params[name]) delete params[name];

      history.pushState(
        {},
        null,
        this.$route.path +
          "?" +
          Object.keys(params)
            .map((key) => {
              return (
                encodeURIComponent(key) + "=" + encodeURIComponent(params[key])
              );
            })
            .join("&")
      );
    },
    showWppDisconnected() {
      const thisIns = this;
      if (!this.showWppDisconnectedActive) {
        this.showWppDisconnectedActive = true;
        this.$vs.dialog({
          color: "danger",
          title: "Atenção",
          text: "O WhatsApp foi desconectado, faça a conexão para continuar usando.",
          accept: () => {
            thisIns.showWppDisconnectedActive = false;
          },
          close: () => {
            thisIns.showWppDisconnectedActive = false;
          },
          acceptText: "Ok",
        });
      }
    },
    setSidebarWidth() {
      if (this.windowWidth < 1200) {
        this.isChatSidebarActive = this.clickNotClose = false;
      } else {
        this.isChatSidebarActive = this.clickNotClose = true;
      }
    },
    startTemplateConversationWPPApi(
      templateName,
      templateText,
      templateWithImageHeader
    ) {
      if (this.startTemplateConversationWPPApiAwait === true) {
        return;
      }
      this.startTemplateConversationWPPApiAwait = true;
      setTimeout(() => {
        this.startTemplateConversationWPPApiAwait = false;
      }, 2000);
      const conversationID = this.activeContact.contactSessionID;
      const sessionID = this.activeContact.sessionID;
      const chatID = this.activeContact.chatID;
      const channel = this.channel;

      const operatorName = this.inboxOperatorsArrayStored.find(
        (i) => i.value === this.currentOperator._id
      ).label;

      let parametersArr = [];

      if (channel === "WhatsApp API") {
        let resultContato = templateText.indexOf("{NOME_CONTATO}");
        let resultAtendente = templateText.indexOf("{NOME_ATENDENTE}");

        if (resultAtendente > -1 || resultContato > -1) {
          let atentendeFirst =
            resultAtendente > -1 && resultAtendente < resultContato;

          if (atentendeFirst) parametersArr.push(operatorName);
          else {
            if (resultContato > -1) parametersArr.push(this.activeContact.name);
            if (resultAtendente > -1) parametersArr.push(operatorName);
          }

          if (atentendeFirst && resultContato > -1)
            parametersArr.push(this.activeContact.name);
        }

        if (Object.keys(this.editVariablesTemplate).length) {
          for (let key in this.editVariablesTemplate) {
            if (templateText.includes(`{${key}}`))
              parametersArr.push(this.editVariablesTemplate[key]);
          }
        }
      } else {
        if (templateText.search("{NOME_ATENDENTE}") > -1)
          parametersArr.push(operatorName);
        if (templateText.search("{NOME_CONTATO}") > -1)
          parametersArr.push(this.activeContact.name);
        if (templateText.includes("{{1}}")) parametersArr.push(operatorName);

        if (templateText.includes("{{2}}"))
          parametersArr.push(this.activeContact.name);

        if (Object.keys(this.editVariablesTemplate).length) {
          for (let key in this.editVariablesTemplate) {
            if (templateText.includes(`{${key}}`))
              parametersArr.push(this.editVariablesTemplate[key]);
          }
        }
      }
      let finalMessage = templateText.replaceAll(
        "{NOME_ATENDENTE}",
        operatorName
      );
      finalMessage = finalMessage.replaceAll(
        "{NOME_CONTATO}",
        this.activeContact.name
      );
      finalMessage = finalMessage.replaceAll("{{1}}", operatorName);
      finalMessage = finalMessage.replaceAll("{{2}}", this.activeContact.name);

      let customVariablesIndex = 3;
      Object.entries(this.editVariablesTemplate).forEach(([key, value]) => {
        if (key && value) {
          finalMessage = finalMessage.replaceAll(`{${key}}`, value);
          finalMessage = finalMessage.replaceAll(
            `{{${customVariablesIndex}}}`,
            value
          );
          customVariablesIndex++;
        }
      });

      let header360Media;
      let header360Buttons;

      if (channel === "WhatsApp 360" && templateWithImageHeader !== undefined) {
        header360Buttons = templateWithImageHeader.find(
          (comp) => comp.type === "BUTTONS"
        );

        // verifica se existe botoes no template e adiciona a mensagem
        if (header360Buttons && header360Buttons.buttons.length > 0) {
          header360Buttons.buttons.forEach((btn) => {
            finalMessage += `\n - ${btn.text}`;
          });
        }

        header360Media = templateWithImageHeader.find(
          (comp) => comp.type === "HEADER"
        );
      }

      let messageType = "text";

      if (
        channel === "WhatsApp API" &&
        templateWithImageHeader &&
        templateWithImageHeader.templateHeaderMedia
      ) {
        messageType = "file";
      } else if (
        header360Media &&
        header360Media.length !== 0 &&
        header360Media.type === "HEADER"
      ) {
        messageType = "file";
      }

      let message = {
        id: uuidV4(),
        author: this.$store.state.user._id,
        type: messageType,
        data: {
          text: finalMessage,
          whatsappApi: channel === "WhatsApp API",
          whatsappApi360Dialog: channel === "WhatsApp 360",
          sms: channel === "SMS",
        },
        timestamp: Date.now(),
        ack: 0,
        channel,
      };

      if (
        channel === "WhatsApp API" &&
        templateWithImageHeader &&
        templateWithImageHeader.templateHeaderMedia
      ) {
        message.data.fileUrl = templateWithImageHeader.templateHeaderMedia;
        message.data.file = templateWithImageHeader.templateHeaderMedia;
        message.data.mimetype =
          templateWithImageHeader.templateHeaderType === "image"
            ? "image/png"
            : "video/mp4";
      } else if (
        header360Media &&
        header360Media.length !== 0 &&
        header360Media.type === "HEADER"
      ) {
        message.data.fileUrl = header360Media.example.header_handle[0];
        message.data.file = header360Media.example.header_handle[0];
        message.data.mimetype =
          header360Media.format === "VIDEO" ? "video/mp4" : "image/png";
      }

      this.$store.dispatch("chat/addMessageList", message);

      socket.emit("wpp api template notification message", {
        message,
        parameters: parametersArr,
        templateName,
        sessionID,
        conversationID,
        chatID,
        templateWithImageHeader,
      });
    },
    selectQuickMessage(val) {
      if (!val) val = this.selectedQuickMessage;

      const message = val.message;

      const operatorName = this.inboxOperatorsArrayStored.find(
        (i) => i.value === this.currentOperator._id
      ).label;

      let finalMessage = message.replaceAll(
        "{NOME_CONTATO}",
        this.activeContact.name
      );

      finalMessage = finalMessage.replaceAll("{NOME_ATENDENTE}", operatorName);

      if (finalMessage.includes("{{2}}")) {
        finalMessage = finalMessage.replaceAll("{{1}}", operatorName);
        finalMessage = finalMessage.replaceAll(
          "{{2}}",
          this.activeContact.name
        );
      } else {
        finalMessage = finalMessage.replaceAll(
          "{{1}}",
          this.activeContact.name
        );
      }

      if (val.header) {
        this.messageFileUrl = val.header.url || null;
        this.messageFileMimetype = val.header.mimetype;
      }

      this.typedMessage = finalMessage;
      this.quickMessagesPopup = false;
    },
    selectDropdown() {
      setTimeout(() => {
        if (this.$refs.mySelect) {
          this.$refs.mySelect.$refs.search.focus();
        }
      }, 30);
    },
    async startContactAttendance() {
      this.$vs.loading();
      const thisIns = this;

      this.$store.dispatch("chat/setCurrentAttendance", []);

      const channel = this.findLastChannelInMessagesList(
        this.messageList,
        this.activeContact
      );
      socket.emit(
        "set conversation operator",
        {
          contactSessionID: this.activeContact.contactSessionID,
          sessionID: this.activeContact.sessionID,
          inAttendance: false,
          conversationID: this.activeContact.id,
          chat: this.activeContact.chatID,
          channel,
        },
        async (response) => {
          if (response.status === "ok") {
            const id = this.activeContact.id;

            const currentItem = this.conversations.update(id, {
              operator: this.currentOperator._id,
              updated: new Date().toISOString(),
              timestamp: Date.now(),
              inAttendance: true,
            });

            await this.$store.dispatch("chat/setActiveTab", 1);
            if (currentItem)
              await thisIns.updateActiveChatUser(currentItem, id);
            this.$vs.loading.close();
          } else {
            this.$vs.notify({
              time: 4000,
              title: this.$t("Error"),
              text: this.$t("NotPossibleToStartConversation"),
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger",
              position: "top-right",
            });
            this.$vs.loading.close();
          }
        }
      );
    },
    sortConversations() {
      this.conversationsDocs = this.conversationsDocs.sort(function (a, b) {
        return b.timestamp - a.timestamp;
      });
      return true;
    },
    openCreateQuickMessagePopup() {
      this.newQuickMessageVisible = false;
      this.createQuickMessagesPopup = true;
    },
    send360TemplateMessage() {
      window.analytics.track(
        "Envio Templates Custom",
        {},
        { groupId: this.$store.state.acc.current_acc.id }
      );
      this.startTemplateConversationWPPApi(
        this.templateData.name,
        this.templateData.message,
        this.templateData.components
      );
      this.editVariablesTemplatesPopup = false;
    },
    openEditVariablesTemplatesPopup() {
      this.editVariablesTemplatesPopup = true;
    },
    getCustomVariablesFromWhatsAppAPI(message) {
      const regex = /\{[^}]+\}/g;
      const exceptions = [
        "{NOME_CONTATO}",
        "{NOME_ATENDENTE}",
        "{LINK_NPS}",
        "{LINK_CSAT}",
      ];
      const matches = message.match(regex) || [];
      const exceptionPlaceholders = matches.filter(
        (placeholder) => !exceptions.includes(placeholder)
      );

      return exceptionPlaceholders;
    },
    createCustomVariableObj(templateData) {
      let name,
        message,
        item,
        customVariables = {},
        objAux = {};

      if (this.channel === "WhatsApp API") {
        name = templateData.template.templateName;
        message = templateData.message;
        item = templateData.template;

        customVariables = this.getCustomVariablesFromWhatsAppAPI(message);

        customVariables.forEach((customVar) => {
          const key = customVar.replace(/[{}]/g, "");
          objAux[key] = null;
        });
      } else {
        name = templateData.name;
        message = templateData.message;
        item = templateData.components;

        if (item.length) {
          customVariables = item.find((el) => el.type === "BODY");

          if (
            customVariables &&
            customVariables.example &&
            customVariables.example.body_text
          ) {
            customVariables.example.body_text.forEach((customVar) => {
              customVar.forEach((item) => {
                const key = item.replace(/[{}]/g, "");
                if (
                  key === "NOME_CONTATO" ||
                  key === "NOME_ATENDENTE" ||
                  key === "LINK_NPS" ||
                  key === "LINK_CSAT"
                )
                  return;
                objAux[key] = null;
              });
            });
          }
        }
      }

      this.templateData = {
        name,
        message,
        components: item,
      };

      if (Object.keys(objAux).length) {
        this.editVariablesTemplate = objAux;
        this.openEditVariablesTemplatesPopup();
      } else {
        this.send360TemplateMessage();
      }
    },
    toogleProfileSidebar(value) {
      this.$store.dispatch("chat/setActiveProfileSidebar", value);
    },
    toggleIsChatPinned(value) {
      this.isChatPinned = value;
    },
    toggleChatSidebar(value = false) {
      if (this.clickNotClose) return;
      this.isChatSidebarActive = value;
    },
    treatConversation(conversation) {
      this.totalConversations = this.totalConversations + 1;
      if (conversation.contactSessionID) return conversation;
      const contactData = conversation.contactData || {};
      let timestamp;
      if (conversation.updated) timestamp = conversation.updated;
      else if (
        conversation.lastMessageDate &&
        conversation.updatedAt &&
        new Date(conversation.lastMessageDate).getTime() >
          new Date(conversation.updatedAt).getTime()
      )
        timestamp = new Date(conversation.lastMessageDate).getTime();
      else timestamp = new Date(conversation.updatedAt).getTime();
      return {
        contactSessionID: conversation._id,
        chatID: conversation.chat,
        sessionID: conversation.sessionid,
        id: conversation._id,
        created: conversation.createdAt,
        updated: conversation.lastMessageDate || conversation.updatedAt,
        contactID: contactData._id,
        type: contactData.type,
        phase: contactData.phase,
        name: contactData.name,
        phone: contactData.phone,
        email: contactData.email,
        contactStatus: contactData.status,
        facebookId: contactData.facebookId,
        instagramId: contactData.instagramId,
        whatsappWAID: contactData.whatsappWAID,
        pendingResponse: conversation.pendingResponse || false,
        profilePic: contactData.profilePic || "",
        operator: conversation.operator || "",
        url: conversation.url,
        status: conversation.online === true ? "online" : "offline",
        online: conversation.online,
        intentions: conversation.intentions,
        entities: conversation.entities,
        tags: conversation.tags,
        tagsContact: contactData.tags || null,
        channel: conversation.channel,
        inAttendance: !conversation.operator
          ? false
          : conversation.operator.length > 0,
        closed: conversation.status === 4,
        visible: true,
        closingReason: conversation.closingReason,
        wppApiOn24hWindow: conversation.wppApiOn24hWindow === true,
        timestamp,
        postponedTo: conversation.postponedTo,
      };
    },
    treatUpdateConversation(conversation) {
      const contactData = conversation.contactData || {};
      let timestamp;
      if (conversation.updated) timestamp = conversation.updated;
      else if (
        conversation.lastMessageDate &&
        conversation.updatedAt &&
        new Date(conversation.lastMessageDate).getTime() >
          new Date(conversation.updatedAt).getTime()
      )
        timestamp = new Date(conversation.lastMessageDate).getTime();
      else timestamp = new Date(conversation.updatedAt).getTime();

      let closed;
      if (conversation.status && conversation.status === 1) closed = false;
      else if (conversation.status && conversation.status === 4) closed = true;
      else closed = undefined;
      return {
        contactSessionID: conversation._id,
        chatID: conversation.chat || undefined,
        sessionID: conversation.sessionid || undefined,
        id: conversation._id || undefined,
        created: conversation.createdAt || undefined,
        updated:
          conversation.lastMessageDate || conversation.updatedAt || undefined,
        contactID: contactData._id || undefined,
        type: contactData.type || undefined,
        phase: contactData.phase || undefined,
        name: contactData.name || undefined,
        phone: contactData.phone || undefined,
        email: contactData.email || undefined,
        facebookId: contactData.facebookId || undefined,
        instagramId: contactData.instagramId || undefined,
        whatsappWAID: contactData.whatsappWAID || undefined,
        pendingResponse: conversation.pendingResponse || undefined,
        profilePic: contactData.profilePic || undefined,
        operator: conversation.operator || undefined,
        url: conversation.url || undefined,
        intentions: conversation.intentions || undefined,
        tags: conversation.tags || undefined,
        channel: conversation.channel || undefined,
        inAttendance: conversation.operator || undefined,
        closed,
        closingReason: conversation.closingReason || undefined,
        wppApiOn24hWindow: conversation.wppApiOn24hWindow || undefined,
        timestamp,
      };
    },
    treatChannel(channel) {
      let channelTreated = channel;
      if (
        (channel && channel === "Website") ||
        channel === "website" ||
        channel === "chat" ||
        channel === "Chat"
      )
        channelTreated = "Webchat";
      return channelTreated;
    },
    toggleFilter(filter) {
      if (this.filter === filter) this.filter = 0;
      else this.filter = filter;
    },
    updateUserProfileId(userId) {
      this.userProfileId = userId;
    },
    async updateActiveChatUser(contact, id) {
      this.showScrollMessagesListToBottom = false;
      await this.$store.dispatch("chat/setChatReplyMessage", null);
      this.messageList = [];
      await this.$store.dispatch("chat/setInboxChannels", []);
      this.pushRouteQueryParams({
        activeTab: this.activeTab,
        conversation: id,
      });
      this.messagesListPage = 1;

      await this.$store.dispatch("chat/setActiveContact", contact);
      await this.$store.dispatch("chat/setActiveChatUser", id);
      await this.toggleChatSidebar();
      await this.$store.dispatch("chat/setUserProfileId", id);
      //verificar tamanho tela
      if (screen.width > 1280)
        this.$store.dispatch("chat/setActiveProfileSidebar", true);
      await this.startLivechat();

      // Available channels setter
      const availableChannels = [];

      const channel = this.treatChannel(contact.channel);

      if (
        channel === "Webchat" &&
        this.inboxChannelsAvailable.find(
          (el) => el.chat === contact.chatID && el.webChatStatus
        )
      )
        availableChannels.push("Webchat");
      if (
        channel === "Webchat AI" &&
        this.inboxChannelsAvailable.find(
          (el) => el.chat === contact.chatID && el.webChatAIStatus
        )
      )
        availableChannels.push("Webchat AI");

      if (
        contact.facebookId &&
        this.inboxChannelsAvailable.find(
          (el) => el.chat === contact.chatID && el.facebookStatus
        )
      )
        availableChannels.push("Facebook");
      if (
        contact.instagramId &&
        this.inboxChannelsAvailable.find(
          (el) => el.chat === contact.chatID && el.instagramStatus
        )
      )
        availableChannels.push("Instagram");

      if (
        this.proactiveChannels.length > 0 &&
        String(contact.phone).length > 0
      ) {
        const proactiveChannels = this.proactiveChannels.find(
          (el) => el.chat === contact.chatID
        );

        if (proactiveChannels) {
          if (proactiveChannels.wpp) {
            availableChannels.push("WhatsApp");
          }
          if (proactiveChannels.wppApi) {
            await this.getQuickMessagesWppApi();
            this.getQuickMessages();
            availableChannels.push("WhatsApp API");
          }
          if (proactiveChannels.wppApi360) {
            await this.getQuickMessagesWppApi();
            this.getQuickMessages();
            availableChannels.push("WhatsApp 360");
          }
          if (proactiveChannels.sms) {
            availableChannels.push("SMS");
          }
        }
      }

      await this.$store.dispatch("chat/setInboxChannels", availableChannels);
    },
    updateContactProp(update) {
      if (update.prop === "phase")
        socket.emit("change contact phase", {
          sessionID: this.activeContact.sessionID,
          chatID: this.activeContact.chatID,
          phase: update.value,
        });
      this.$store.dispatch("chat/updateActiveContactField", {
        field: update.prop,
        data: update.value,
      });
      this.$nextTick(() => {
        this.conversations.update(update._id, { [update.prop]: update.value });
      });
    },
    sleep(ms) {
      return new Promise((resolveFunc) => setTimeout(resolveFunc, ms));
    },
    async updateConversation(
      conversation,
      {
        operator,
        intentions,
        entities,
        name,
        email,
        phone,
        field,
        value,
        postponedTo,
      }
    ) {
      const contactSessionID = conversation._id;
      let id = contactSessionID;

      const currentItem = this.conversations.findOne(id);
      if (!currentItem) {
        if (conversation && conversation.status !== 1) return;
        if (
          this.currentOperator.acl === "admin" ||
          (operator && operator === this.currentOperator._id) ||
          !conversation.operator ||
          (conversation.operator && conversation.operator === "")
        ) {
          const currentEntities =
            entities && entities.length > 0 ? entities : conversation.entities;
          const currentIntentions =
            intentions && intentions.length > 0
              ? intentions
              : conversation.intentions;
          if (
            this.entitiesArray.length > 0 &&
            (!currentEntities || currentEntities.length === 0)
          ) {
            return;
          }
          if (
            this.entitiesArray.length > 0 &&
            currentEntities &&
            currentEntities.length > 0 &&
            !currentEntities.some((entity) =>
              this.entitiesArray.includes(entity)
            )
          ) {
            return;
          }
          if (
            (!this.oprAllIntentions &&
              currentIntentions &&
              currentIntentions.length > 0 &&
              !currentIntentions.some((intent) =>
                this.intentionsArrayStored.includes(intent)
              )) ||
            (!this.oprAllIntentions &&
              currentIntentions &&
              currentIntentions.length === 0 &&
              !this.intentionsArrayStored.includes(""))
          ) {
            return;
          }
          if (
            !this.attendanceSyncStarted /*||
            (this.attendanceSyncStarted &&
              conversation.timestamp > this.attendanceSyncStarted)*/
          ) {
            await this.createNewConversation(conversation);
          }
        }
      } else {
        const updateQuery = {};
        if (operator) updateQuery.operator = operator;
        else if (operator === "") {
          updateQuery.operator = null;
          updateQuery.inAttendance = false;
        }
        if (intentions) updateQuery.intentions = intentions;
        if (entities) updateQuery.entities = entities;
        if (name) updateQuery.name = name;
        if (email) updateQuery.email = email;
        if (phone) updateQuery.phone = phone;
        if (field) updateQuery[field] = value;
        if (postponedTo || postponedTo === null)
          updateQuery.postponedTo = postponedTo
            ? new Date(postponedTo).toISOString()
            : postponedTo;

        if (Object.values(updateQuery).length > 0)
          this.conversations.update(id, updateQuery);

        if (
          contactSessionID === this.activeChatUser &&
          (postponedTo || postponedTo === null)
        ) {
          this.updateActiveChatUser(this.conversations.findOne(id), id);
        }

        if (conversation && conversation.status !== 1) {
          if (contactSessionID === this.activeChatUser)
            this.resetActiveChatUser();
          this.totalConversations = this.totalConversations - 1;
          await this.$store.dispatch("chat/removeElementOnChatContacts", id);
          return;
        }
        if (field) {
          if (this.activeContact && this.activeContact.id === currentItem.id)
            this.updateActiveChatUser(this.conversations.findOne(id), id);
        } else {
          if (
            !currentItem.inAttendance &&
            operator &&
            operator !== this.currentOperator._id &&
            this.currentOperator.acl !== "operator"
          )
            this.conversations.update(id, { inAttendance: true });

          let seize = false;
          let update = false;
          if (intentions) {
            if (
              intentions.length === 0 &&
              !this.oprAllIntentions &&
              !this.intentionsArrayStored.includes("")
            )
              seize = true;
            else if (
              !this.oprAllIntentions &&
              intentions.length > 0 &&
              !intentions.some((intent) =>
                this.intentionsArrayStored.includes(intent)
              )
            )
              seize = true;
            else update = true;
          }

          if (entities) {
            if (
              this.entitiesArray.length > 0 &&
              entities.length > 0 &&
              !entities.some((entity) => this.entitiesArray.includes(entity))
            )
              seize = true;
            else update = true;
          }
          if (seize) {
            if (id === this.activeChatUser) this.resetActiveChatUser();
            this.totalConversations = this.totalConversations - 1;
            this.conversations.delete(id);
          } else if (update) {
            if (currentItem && !currentItem.visible)
              this.playAudio(newConversationNotification);
            this.conversations.update(id, { visible: true });
          }

          if (
            operator &&
            operator !== this.currentOperator._id &&
            this.currentOperator.acl === "operator"
          ) {
            this.conversations.delete(id);
            if (id === this.activeChatUser) this.resetActiveChatUser();
          }
        }
      }
    },
    isValidConversation(data) {
      return (
        data.channel !== undefined &&
        data.chatID !== undefined &&
        data.closed !== undefined &&
        data.contactID !== undefined &&
        data.contactSessionID !== undefined &&
        data.id !== undefined &&
        data.intentions !== undefined &&
        data.name !== undefined &&
        data.phase !== undefined &&
        data.sessionID !== undefined &&
        data.timestamp !== undefined &&
        data.type !== undefined &&
        data.visible !== undefined
      );
    },
    async startExternalLinkConversation(startConversation) {
      try {
        let conv;

        const response = await axios.get("/p/chat/inbox/conversations", {
          params: {
            acc: this.$store.state.acc.current_acc.id,
            lastTimestamp: this.lastTimestamp,
            firstID: this.firstID,
            lastID: this.lastID,
            byID: undefined,
            loadAttendanceVersion: 2,
            loadType: null,
            loadSize: 1,
            searchString: startConversation.phone,
          },
        });

        const conversationsSearch = response.data.data.conversations;

        const { contacts } = response.data.data;

        if (conversationsSearch && conversationsSearch.length > 0) {
          conv = conversationsSearch[0];
          if (!conv.contactData && contacts) {
            conv.contactData = contacts.find(
              (el) => el._id === conv.inboxContact
            );
          }
          conv = this.treatConversation(conv);
          this.conversations.createOrUpdate(conv);
        }

        if (conv) {
          if (this.activeTab !== 1)
            await this.$store.dispatch("chat/setActiveTab", 1);
          this.updateActiveChatUser(conv, conv.id);
          this.addConversationPopup = false;
        } else {
          this.addConversationPopup = true;
          this.validPhone = true;
          this.addConversationLoading = false;
          this.addConversationNameDisabled = false;
          this.addConversationPhoneDisabled = false;

          this.addConversationIntention = "";
          this.addConversationEntity = "";
          this.addConversationContact = "";
          this.addConversationName = startConversation.name;
          this.addConversationPhone = startConversation.phone;

          localStorage.removeItem("startConversation");
        }
      } catch (e) {
        return e;
      }
    },
    async updateConversations(loadAttendanceVersion = 1, byID = null) {
      try {
        if (!this.isReady || byID) {
          this.$vs.loading();
          loadAttendanceVersion = 2;
        }

        if (this.searchQuery.length > 0)
          this.conversationsLoadingSkeletonUp =
            this.conversationsLoadingSkeleton;

        this.loadingConversationsSpin = true;
        this.currentTabChatsLength = this.currentTabChats.length;
        const loadSize = this.contactListHeightConfig.itemsPerPage || 30;
        const loadType =
          this.activeTab === 0 ? "waitingAttendance" : "inAttentance";

        axios
          .get("/p/chat/inbox/conversations", {
            params: {
              acc: this.$store.state.acc.current_acc.id,
              lastTimestamp: this.lastTimestamp,
              firstID: this.firstID,
              lastID: this.lastID,
              byID: byID || undefined,
              loadAttendanceVersion,
              loadType,
              loadSize,
              searchString: this.searchQuery,
            },
          })
          .then(async (response) => {
            if (response && response.data && response.data.data) {
              const {
                updatedConversations,
                conversations,
                contacts,
                conversationsNumbers,
              } = response.data.data;
              if (conversationsNumbers)
                this.conversationsNumbers = conversationsNumbers;
              if (updatedConversations) {
                for (let i = 0; i < updatedConversations.length; i += 1) {
                  const el = this.treatUpdateConversation(
                    updatedConversations[i]
                  );
                  if (el) this.conversations.update(el.id, el);
                }
              }

              if (conversations && conversations.length > 0) {
                for (let i = 0; i < conversations.length; i += 1) {
                  if (!conversations[i].contactData && contacts) {
                    conversations[i].contactData = contacts.find(
                      (el) => el._id === conversations[i].inboxContact
                    );
                  }
                  conversations[i] = this.treatConversation(conversations[i]);
                  this.conversations.createOrUpdate(conversations[i]);
                }

                const lastTimestamp = Math.max(
                  ...conversations.map((o) => o.timestamp)
                );
                this.lastTimestamp =
                  this.lastTimestamp < lastTimestamp
                    ? lastTimestamp
                    : this.lastTimestamp;

                if (!byID) {
                  if (!this.firstID || conversations[0]._id > this.firstID)
                    this.firstID = conversations[0].id;
                  this.lastID = conversations[conversations.length - 1].id;
                }
              }

              if (byID) {
                const id = byID;
                const currentItem = this.conversations.findOne(id);
                if (currentItem && currentItem.inAttendance) {
                  if (this.activeTab !== 1)
                    await this.$store.dispatch("chat/setActiveTab", 1);
                  this.updateActiveChatUser(currentItem, id);
                  this.addConversationPopup = false;
                }
              }

              if (this.$route.query.conversation) {
                if (
                  this.$route.query.notification ||
                  this.$route.query.activeTab === "0" ||
                  this.$route.query.activeTab === "1" ||
                  this.$route.query.activeTab === 0 ||
                  this.$route.query.activeTab === 1
                ) {
                  const conversations = this.conversations.getAll();
                  const _conv = conversations.find(
                    (el) =>
                      el.contactSessionID === this.$route.query.conversation
                  );
                  if (_conv) {
                    await this.$store.dispatch(
                      "chat/setActiveTab",
                      _conv.operator ? 1 : 0
                    );
                    this.updateActiveChatUser(_conv, _conv.id);
                  } else {
                    this.$vs.notify({
                      time: 4000,
                      title: this.$t("Error"),
                      text: this.$t(
                        "ErrorAttendanceAnotherOperatorOrNotFounded"
                      ),
                      iconPack: "feather",
                      icon: "icon-alert-circle",
                      color: "danger",
                      position: "top-right",
                    });
                  }
                } else {
                  await this.$store.dispatch("chat/setActiveTab", 2);
                }
              }
              this.$vs.loading.close("#chatlog > .con-vs-loading");
              this.$vs.loading.close();
              this.loadingConversationsSpin = false;
              this.socketReconnect = false;
              this.socketLastPing = Date.now();

              if (
                loadAttendanceVersion === 2 &&
                conversations &&
                conversations.length > 0
              ) {
                this.showLoadMoreConversations = true;
              } else this.finishSync();
              let startConversation = localStorage.getItem("startConversation");
              if (startConversation) {
                startConversation = JSON.parse(startConversation);
                await this.startExternalLinkConversation(startConversation);
              }
            } else this.finishSync();

            this.calculateContactListHeight(true);
            this.sortConversations();
            this.calculateContactListHeightScroll(
              document.querySelectorAll(".contact-list-item")
            );

            this.$vs.loading.close();
            this.conversationsLoadingSkeletonUp = [];
          })
          .catch(async () => {
            this.loadingConversationsSpin = false;
            this.conversationsLoadingSkeletonUp = [];
            this.$vs.notify({
              time: 4000,
              title: this.$t("Error"),
              text: this.$t("ErrorOnLoadingConversations"),
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger",
              position: "top-right",
            });
          });
      } catch (e) {
        this.$vs.notify({
          time: 4000,
          title: this.$t("UnexpectedError"),
          text: this.$t("ErrorOnLoadingConversations"),
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
          position: "top-right",
        });
        this.conversationsLoadingSkeletonUp = [];
        this.loadingConversationsSpin = false;
      }
    },
    validatePhone(e) {
      this.validPhone = e.valid;
      if (e && e.countryCallingCode)
        this.addConversationPhoneCountryCode = String(e.countryCallingCode);
    },
    withPopper(dropdownList, component, { width }) {
      dropdownList.style.width = width;
      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: this.placement,
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, -1],
            },
          },
          {
            name: "toggleClass",
            enabled: true,
            phase: "write",
            fn({ state }) {
              component.$el.classList.toggle(
                "drop-up",
                state.placement === "top"
              );
            },
          },
        ],
      }); //teste

      return () => popper.destroy();
    },
    isIaMessage(author) {
      const iaAuthors = ["chatSummary", "sentimentAnalysis"];
      return iaAuthors.includes(author);
    },
    async getMessageHistoricForGpt() {
      const messages = [...this.messageList].reverse(),
        historic = [];
      let texts = 0;

      messages.forEach((message) => {
        if (texts >= 8000) {
          return;
        }
        if (message.type === "text" && !this.isIaMessage(message.author)) {
          texts += message.data.text.length;
          if (message.author === "me") {
            historic.push({
              role: "user",
              content: message.data.text,
            });
          } else {
            historic.push({
              role: "assistant",
              content: message.data.text,
            });
          }
        }
      });

      return historic;
    },
    async getMessagesForSummary() {
      const messages = [...this.messageList].reverse();
      let historic = "";
      let texts = 0;

      messages.forEach((message) => {
        if (texts >= 8000) {
          return;
        }
        if (message.type === "text" && !this.isIaMessage(message.author)) {
          texts += message.data.text.length;
          historic +=
            (message.author === "me" ? "Usuário: " : "Assistente: ") +
            message.data.text +
            "\n";
        }
      });

      return [
        {
          role: "user",
          content: historic,
        },
      ];
    },
    async generateChatGptSuggestion() {
      window.analytics.track(
        "Sugerir resposta",
        {},
        { groupId: this.$store.state.acc.current_acc.id }
      );
      this.$vs.loading();
      this.chatGptSuggestionGenerated = true;
      const payload = await this.getMessageHistoricForGpt();

      try {
        const generatedMessage = await axios.post(
          "/p/chat/ai/generate/message",
          {
            messages: payload.reverse(),
            acc: this.$store.state.acc.current_acc.id,
            chat: this.activeContact.chatID,
            conversationId: this.activeContact.id,
            type: "answer",
          }
        );

        if (
          generatedMessage &&
          generatedMessage.data &&
          generatedMessage.data.data
        ) {
          this.typedMessage = generatedMessage.data.data;
        }
        this.$vs.loading.close();
      } catch (err) {
        this.$vs.notify({
          time: 4000,
          title: this.$t("Error"),
          text: this.$t("ErrorOnGeneratingChatGptSuggestion"),
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
          position: "top-right",
        });
        this.chatGptSuggestionGenerated = false;
        this.$vs.loading.close();
      }
    },
    async resumeConversation() {
      window.analytics.track(
        "Resumir conversa",
        {},
        { groupId: this.$store.state.acc.current_acc.id }
      );

      this.$vs.loading();
      const payload = await this.getMessagesForSummary();

      try {
        const generatedMessage = await axios.post(
          "/p/chat/ai/generate/message",
          {
            messages: payload.reverse(),
            acc: this.$store.state.acc.current_acc.id,
            chat: this.activeContact.chatID,
            conversationId: this.activeContact.id,
            type: "chatSummary",
          }
        );

        if (
          generatedMessage &&
          generatedMessage.data &&
          generatedMessage.data.data
        ) {
          this.sendMsg(null, "chatSummary", generatedMessage.data.data);
        }
        this.$vs.loading.close();
      } catch (err) {
        this.$vs.notify({
          time: 4000,
          title: this.$t("Error"),
          text: this.$t("ErrorOnGeneratingChatGptSuggestion"),
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
          position: "top-right",
        });
        this.$vs.loading.close();
      }
    },
    async sentimentAnalysis() {
      window.analytics.track(
        "Análise de sentimento",
        {},
        { groupId: this.$store.state.acc.current_acc.id }
      );

      this.$vs.loading();
      const payload = await this.getMessagesForSummary();

      try {
        const generatedMessage = await axios.post(
          "/p/chat/ai/generate/message",
          {
            messages: payload.reverse(),
            acc: this.$store.state.acc.current_acc.id,
            chat: this.activeContact.chatID,
            conversationId: this.activeContact.id,
            type: "sentimentAnalysis",
          }
        );

        if (
          generatedMessage &&
          generatedMessage.data &&
          generatedMessage.data.data
        ) {
          this.sendMsg(null, "sentimentAnalysis", generatedMessage.data.data);
        }
        this.$vs.loading.close();
      } catch (err) {
        this.$vs.notify({
          time: 4000,
          title: this.$t("Error"),
          text: this.$t("ErrorOnGeneratingChatGptSuggestion"),
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
          position: "top-right",
        });
        this.$vs.loading.close();
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    document.body.style.removeProperty("overflow");
    this.changeWindowTitle(0);
    this.$store.dispatch("chat/setSearchQuery", "");
    socket.removeAllListeners();
    if (socket.connected) socket.disconnect();
    next();
  },
};
</script>

<style lang="scss">
.dropdownActionsBtn,
.suggestionBtn.vs-button-warning.vs-button-border {
  background: rgba(255, 159, 67, 0.08) !important;
}
.dropdownActionsBtn:hover .vs-button-warning.vs-button-border,
.suggestionBtn:hover.vs-button-warning.vs-button-border {
  background: rgba(255, 159, 67, 0.2) !important;
}
.dropdownActionsListBtn .vs-dropdown--item .vs-dropdown--item-link {
  padding: 0 !important;

  button {
    width: 100%;
    justify-content: left !important;
    border: 1px solid transparent !important;

    &:hover {
      border-color: rgb(255, 159, 67) !important;
    }
  }
}
.chat__log {
  height: calc(100vh - 286px);
}
.chat__log_closed {
  height: calc(100vh - 68px);
}
.width-textarea {
  width: calc(100% - 200px);
}
.msg-reply {
  padding: 5px;
  background-color: #7267f02d;
  border-radius: 5px;
  margin-bottom: 5px;
  border-left: 8px solid #7367f0;

  .reply-participant {
    color: #7367f0;
  }
}
.clusterize-scroll {
  overflow: auto;
  height: calc(100vh - 215px);
}
.EndMessage {
  background: #333;
  position: absolute;
  top: 100%;
}

.vselectNoWrap {
  .vs__search {
    margin-top: -30px;
  }
  input.vs__search:focus {
    padding-top: 30px !important;
  }
  span.vs__selected {
    width: 80px !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    display: inline-block !important;
    text-overflow: ellipsis !important;
  }
}
/* .justify-items-center, .vs-tabs--li .vs-tabs--btn {
  justify-content: center !important;
} */

.backdrop-filter {
  background-color: #cccccc;
  filter: blur(500px);
  z-index: 999;
}

.p-5-mutaded {
  padding: 1.25rem !important;
}
#chat-app {
  top: 0;
  bottom: 0;
  right: 0;
  @media screen and (max-width: 450px) {
    right: 0;
  }
  left: -0.5rem;
}
#chat-log .vs-con-items {
  flex-wrap: wrap;
}
.chat-content-scroll-area {
  height: calc(100vh - 285px) !important;
  @media screen and (max-width: 450px) {
    height: calc(100vh - 315px) !important;
  }
}
#chat-input {
  max-height: 217px;
}
.con-vs-dropdown--menu {
  z-index: 92000 !important;
}
#popup-inbox-conversation .vs-popup {
  max-width: 450px !important;
}
#chat-list-sidebar > div {
  @media screen and (max-width: 450px) {
    max-width: 100% !important;
  }
  @media screen and (min-width: 451px) {
    max-width: 350px !important;
  }
}
#chat-list-sidebar > .vs-sidebar,
#chat-profile-info-sidebar > .vs-sidebar {
  margin-top: 0px !important;
}
.label-onboarding {
  font-size: 0.85rem;
  background-color: white;
  margin-left: 1rem;
  padding: 0 0.5rem 0 0.5rem;
  width: fit-content;
  position: relative;
  bottom: -10px;
  order: -1;
  color: #adadad;
}
.input-onboarding {
  padding-left: 8px;
}
.input-onboarding:focus {
  border: solid 1px rgba(var(--vs-primary), 1);
}
.vue-tel-input {
  border: 1px solid rgba(0, 0, 0, 0.2);
}
.vue-tel-input:focus-within {
  border-color: rgba(var(--vs-primary), 1);
  -webkit-box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
}
.input-onboarding:focus + span + .label-onboarding,
.input-onboarding:focus + .label-onboarding,
.vue-tel-input:focus-within + span + .label-onboarding {
  color: rgba(var(--vs-primary), 1);
}

#tabs-inbox > div > ul > li > button {
  padding-bottom: 15px;
}
#inbox-search > div {
  border: 0;
}
#inbox-search > ul {
  border-top: 1px solid rgba(60, 60, 60, 0.26);
}
#inbox-search > div > .vs__actions > span {
  color: #b8c2cc;
}
.search-active > div .vs__actions > span {
  color: #7367f0 !important;
}
#inbox-search > div .vs__selected {
  position: relative;
  left: 10px;
}

#collapse-inbox-1 > header {
  display: flex;
  justify-content: center;
  margin-right: 0.5rem;
  padding-top: 0;
  padding-bottom: 0;
}

#collapse-inbox-1 > header .icon-header {
  right: 14px;
  color: #b8c2cc;
}
#collapse-inbox {
  padding-right: 0;
  padding-bottom: 0;
  padding-top: 0;
}
#collapse-users-1 > header {
  display: flex;
  justify-content: center;
  margin-right: 0.5rem;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-top: 0;
}
#collapse-users {
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-top: 0;
}
#collapse-users-1 > header .icon-header {
  color: #b8c2cc;
}
#collapse-search {
  padding-right: 0;
  padding-bottom: 0;
  padding-top: 0;
}
#collapse-search > div > header {
  padding: 0;
}
#collapse-search-1 > header .icon-header {
  color: #b8c2cc;
}
.media-close {
  margin-left: -20px;
  width: 20px !important;
  height: 20px !important;
  background-color: #c1c1c1 !important;
}
.media-close:hover {
  background-color: #979797 !important;
}
.quick-message:hover {
  opacity: 0.5 !important;
}

.flip-list-move {
  transition: transform 1s;
  transition-duration: 0.4s;
  transition-delay: 0s;
  transition-timing-function: linear;
}
.messageMediaPopup {
  padding-left: 2px;
  position: absolute;
  bottom: 218px;

  display: flex;
  flex-direction: column;
  justify-content: center;
}
.quickMessageMediaPopup * {
  max-height: 300px;
  max-width: 300px;
}
.newQuickMessage {
  position: absolute;
  bottom: 220px;
  max-width: 1000px;
  z-index: 9999999;
  height: 250px;
  overflow: scroll;
  background-color: white;
  width: 100%;

  .contact__container {
    padding: 5px;
    color: black;
    &:hover,
    &:focus {
      background-color: #7367f0;
      color: white;
      cursor: pointer;
    }
  }
}

// Add conversation popup - telephone country select
.vti__dropdown-list {
  z-index: 12;
}

.chatGptSuggestionButton {
  display: inline-flex;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 6px;
  border: 1px solid #ffd200;
  background: rgba(255, 210, 0, 0.07);
}
</style>
