<template lang="html">
  <div
    :class="[`vs-tabs-${color}`, `vs-tabs-position-${position}`]"
    class="con-vs-tabs vs-tabs"
  >
    <div class="con-ul-tabs">
      <ul
        ref="ul"
        :class="[`ul-tabs-${alignment}`]"
        class="ul-tabs vs-tabs--ul"
      >
        <li
          :key="`vs-tab-${index}`"
          v-for="(child, index) in children"
          ref="li"
          :class="{ activeChild: childActive === index }"
          :style="childActive === index ? styleTab : {}"
          style="height: 43px"
          class="vs-tabs--li flex"
          @mouseover="hover = true"
          @mouseout="hover = false"
        >
          <button
            v-bind="child.attrs"
            :style="styleAlignIcon(child.icon)"
            class="vs-tabs--btn justify-center"
            type="button"
            @click="activeChild(index)"
            v-on="child.listeners"
            :disabled="isDisabled(index)"
          >
            <vs-icon
              v-if="child.icon"
              :icon-pack="child.iconPack"
              :icon="child.icon"
              :color="color"
              class="vs-tabs--btn-icon"
            ></vs-icon>
            <span v-if="child.label">{{ child.label }}</span>
            <small
              class="badge-tab"
              v-if="counterBadge[index] && counterBadge[index] > 0"
            >
              {{ counterBadge[index] }}
            </small>
          </button>

          <button
            v-if="child.tag"
            class="vs-tabs--btn-tag"
            @click="clickTag(child)"
          >
            <vs-icon
              :icon-pack="child.iconPack"
              :icon="child.tag"
              :color="child.tagColor"
            ></vs-icon>
          </button>
        </li>
      </ul>
      <span :style="stylex" class="line-vs-tabs" />
    </div>
    <div class="con-slot-tabs">
      <slot />
    </div>
  </div>
</template>

<script>
import _color from "@/assets/utils/color.js";
export default {
  name: "VsDuotalkInboxTabs",
  props: {
    value: {
      default: 0,
      type: [Number, String],
    },
    color: {
      default: "primary",
      type: String,
    },
    tagColor: {
      default: "primary",
      type: String,
    },
    alignment: {
      default: "left",
      type: String,
    },
    position: {
      default: "top",
      type: String,
    },
    counterBadge: {
      type: Array,
    },
    blockWaitingServiceTab: {
      default: false,
      type: Boolean,
    },
  },
  data: () => ({
    topx: "auto",
    heightx: 2,
    hover: false,
    children: [
      {
        label: "",
        icon: "icon-clock",
        iconPack: "feather",
        counterBadge: 0,
        tag: "",
        id: 0,
        listeners: {},
        attrs: {
          id: "tab-inbox-0",
          title: "Aguardando atendimento",
        },
      },
      {
        label: "",
        icon: "icon-message-square",
        iconPack: "feather",
        counterBadge: 0,
        tag: "",
        id: 1,
        listeners: {},
        attrs: {
          id: "tab-inbox-1",
          title: "Em atendimento",
        },
      },
      {
        label: "",
        icon: "icon-archive",
        iconPack: "feather",
        counterBadge: 0,
        tag: "",
        id: 2,
        listeners: {},
        attrs: {
          id: "tab-inbox-2",
          title: "Atendimentos Encerradas",
        },
      },
    ],
    childActive: 0,
    leftx: 0,
    widthx: 0,
    these: false,
  }),
  computed: {
    styleTab() {
      return {
        color: _color.getColor(this.color, 1),
      };
    },
    stylex() {
      return {
        top: `${this.topx}px`,
        left: `${this.leftx}px`,
        width: `${this.widthx}px`,
        height: `${this.heightx}px`,
        background: `linear-gradient(30deg, ${_color.getColor(
          this.color,
          1
        )} 0%, ${_color.getColor(this.color, 0.5)} 100%)`,
        boxShadow: `0px 0px 8px 0px ${_color.getColor(this.color, 0.5)}`,
      };
    },
  },
  watch: {
    value(index) {
      const activeIndex = this.parseIndex(index);
      this.activeChild(activeIndex);
    },
  },
  mounted() {
    const activeIndex = this.parseIndex(this.value);
    this.childActive = activeIndex;
    this.$nextTick(() => {
      this.activeChild(activeIndex, true);
    });
  },
  methods: {
    isDisabled(index) {
      if (
        this.$store.state.user.userRole == "operator" &&
        index === 0 &&
        this.blockWaitingServiceTab
      ) {
        if (this.childActive === 0)
          this.$nextTick(() => {
            this.activeChild(1, true);
          });
        return true;
      }
      return false;
    },
    clickTag(child) {
      this.$emit("click-tag", child);
    },
    styleAlignIcon(icon) {
      return icon ? "display:flex;align-items:center" : "";
    },
    parseIndex(index) {
      let activeIndex = this.childActive;
      if (index < 0) activeIndex = 0;
      else activeIndex = parseInt(index);
      return activeIndex;
    },
    activeChild(index, initialAnimation) {
      initialAnimation = !!initialAnimation;
      const elem = this.$refs.li[index];
      if (this.childActive === index && !initialAnimation) {
        this.these = true;
        elem.classList.add("isActive");
        setTimeout(() => {
          elem.classList.remove("isActive");
          this.these = false;
        }, 200);
      }
      this.$children.map((item, item_index) => {
        if (item_index !== index) {
          item.active = false;
        }
      });
      if (this.childActive > index) {
        this.$children[index].invert = true;
        this.$children[this.childActive].invert = false;
      } else {
        this.$children[this.childActive].invert = true;
        this.$children[index].invert = false;
      }
      this.$children[index].active = true;
      this.childActive = index;
      this.$emit("input", this.childActive);
      if (this.position === "left" || this.position === "right") {
        this.$children[index].vertical = true;
      }
      this.changePositionLine(elem, initialAnimation);
    },
    changePositionLine(elem, initialAnimation) {
      const update = () => {
        this.leftx = elem.offsetLeft;
        this.widthx = elem.offsetWidth;
        this.topx = elem.offsetHeight - 5;
      };
      if (!initialAnimation) {
        update();
      } else {
        setTimeout(update, 100);
      }
    },
  },
};
</script>
<style scoped>
.badge-tab {
  padding: 2px 5px;
  border: solid 1px #ea5455;
  background-color: #ea5455;
  color: white;
  border-radius: 50%;
  margin-left: 5px;
  margin-top: -2px;
}
</style>
